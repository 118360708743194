import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { Modal } from "flowbite";
import toast from "react-hot-toast";

const PrizeClaim = () => {

    const location = useLocation();
    const isDashboard = location.pathname === '/';

    const navigate = useNavigate();
    const [prizeClaims, setPrizeClaims] = useState([]);



    const [gameType, setGametype] = useState('');
    const [prizeamt, setPrizeamt] = useState('');
    const [contact, setContact] = useState('');
    const [account, setAccount] = useState('');
    const [sortcode, setSortcode] = useState('');
    const [status, setStatus] = useState('');
    const [username, setUsername] = useState('');
    const [id, setId] = useState('');
    const [kioskname, setKioskname] = useState('');

    const [refreshKey, setRefreshKey] = useState(0);

    const token = sessionStorage.getItem("bearer_token");
    const auth_token = sessionStorage.getItem("token");

    const permissions = JSON.parse(sessionStorage.getItem("permissions"));

    const [permissionModule, setPermissionModule] = useState([]);


    // pagination and search
    const [filters, setFilters] = useState({
        gameType: "",
        claimStatus: "",
        winnerName: "",
        startDate: "",
        endDate: "",
        page: 1,
        limit: 10,
    });
    const [pagination, setPagination] = useState({
        totalRecords: 0,
        currentPage: 1,
        totalPages: 0,
        limit: 0,
    });

    useEffect(() => {
        const fetchPrizeClaims = async () => {
            try {
                // Step 1: Fetch prize claims data
                const queryParams = Object.entries(filters)
                    .filter(([_, value]) => value) // Only include parameters with non-empty values
                    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
                    .join("&");
                const prizeClaimsResponse = await axios.get(
                    process.env.REACT_APP_API_URL + `api/prize-claims/get-prize-claim?${queryParams}`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`,
                        "auth_token": `${auth_token}`,
                    },
                });


                if (prizeClaimsResponse.data.success) {
                    const prizeClaimsData = prizeClaimsResponse.data.data.prizeClaims;
                    setPrizeClaims(prizeClaimsData);
                    setPagination({
                        totalRecords: prizeClaimsResponse.data.data.pagination.totalRecords,
                        currentPage: prizeClaimsResponse.data.data.pagination.currentPage,
                        totalPages: prizeClaimsResponse.data.data.pagination.totalPages,
                        limit: prizeClaimsResponse.data.data.pagination.limit,
                    });
                } else {
                    // setError("Failed to fetch prize claims.");
                    toast.error("Something went wrong.")
                }
            } catch (err) {
                if (err.response?.status === 400) {
                    // Handle invalid token error
                    if (err.response.data === "invalid token") {
                        navigate("/login"); // Redirect to login page
                    } else {
                        toast.error(err.response.data); // Show other 400 errors
                    }
                } else {
                    // Handle other errors
                    toast.error("No Prize Claims found.");
                }
            }
        };

        fetchPrizeClaims();
        if (permissions) {

            setPermissionModule(permissions.find(
                (permission) => permission.Module.moduleName === 'prizeClaim'
            ));
        }
    }, [refreshKey, filters]);



    // initialize modal
    useEffect(() => {
        // Initialize the modal on component mount
        const modalElement = document.getElementById("popup-modal");
        if (modalElement) {
            const modal = new Modal(modalElement);
            modal.hide(); // Ensure the modal is initially hidden
        }
    }, []);

    // format date for table
    const formatDate = (dateString) => {
        const date = new Date(dateString.replace(" ", "T")); // Replace space with T for compatibility
        const options = { month: 'long', day: 'numeric', year: 'numeric' };
        const formattedDate = date.toLocaleDateString('en-US', options);
        const time = date.toLocaleTimeString('en-US', { hour12: false });
        return `${formattedDate} ${time}`;
    };

    // status div
    const renderStatus = (status) => {
        switch (status) {
            case 'completed':
                return (
                    <p className="bg-[#DEF7EC] text-sm text-[#03543F] px-3 py-1 rounded-full md:w-full text-center">Completed</p>

                );
            case 'in-progress':
                return (
                    <p className="bg-[#E1EFFE] text-sm text-[#1E429F] px-3 py-1 rounded-full md:w-full text-center">
                        In Progress
                    </p>
                );
            case 'cancelled':
                return (
                    <p className="bg-[#FBD5D5] text-sm text-red-500 px-3 py-1 rounded-full md:w-full text-center">Cancelled</p>
                );
            default:
                return (
                    <p className="bg-gray-200 text-sm text-gray-700 px-3 py-1 rounded-full md:w-full text-center">
                        Pending
                    </p>
                );
        }
    }

    // get single prize claim data
    const getClaimData = async (id) => {
        const modalElement = document.getElementById("popup-modal");
        if (modalElement) {
            const modal = new Modal(modalElement);
            modal.show();
        }

        try {
            

            const res = await axios.get(process.env.REACT_APP_API_URL + `api/prize-claims/get-prize-claim/${id}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });


            if (res.data.prizeClaim) {
                try {
                    const user = await axios.get(process.env.REACT_APP_API_URL + `api/user/get-user/${res.data.prizeClaim.userId}`, {
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": `Bearer ${token}`,
                            "auth_token": `${auth_token}`,
                        },
                    });


                    setKioskname(user.data.user.name);
                } catch (err) {
                    console.log(err);

                }
                const claim = res.data.prizeClaim;
                setUsername(claim.winnerName);
                setGametype(claim.gameType);
                setPrizeamt(claim.prizeAmount);
                setContact(claim.contactNumber);
                setAccount(claim.bankAccount);
                setSortcode(claim.sortCode);
                setStatus(claim.claimStatus || "Pending");

                setId(claim.id);
            } else {
                toast.error("No prize claims found");
                console.warn("No prize claims in response:", res.data.prizeClaim);
            }
        } catch (err) {
            if (err.response?.status === 400) {
                // Handle invalid token error
                if (err.response.data === "invalid token") {
                    navigate("/login"); // Redirect to login page
                } else {
                    toast.error(err.response.data); // Show other 400 errors
                }
            } else {
                // Handle other errors
                toast.error("An unexpected error occurred.");
            }
        }
    };


    // update single claim data
    const handleUpdateClaim = async (e) => {
        e.preventDefault();
        const update_id = id;

        const postData = {
            "gameType": gameType,
            "prizeAmount": prizeamt,
            "claimStatus": status,
            "bankAccount": account,
            "sortCode": sortcode,
            "contactNumber": contact,
        };

        try {

            const res = await axios.put(process.env.REACT_APP_API_URL + `api/prize-claims/update-prize-claim/${update_id}`, postData, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });
            toast.success(res.data.message);
            setRefreshKey((prevKey) => prevKey + 1);

        } catch (err) {
            if (err.response?.status === 400) {
                // Handle invalid token error
                if (err.response.data === "invalid token") {
                    navigate("/login"); // Redirect to login page
                } else {
                    toast.error(err.response.data); // Show other 400 errors
                }
            } else {
                // Handle other errors
                toast.error("An unexpected error occurred.");
            }
        }

    };


    // search and pagination
    const handleFilterChange = (key, value) => {
        setFilters((prev) => ({
            ...prev,
            [key]: value,
            page: 1, // Reset to the first page on filter change
        }));
    };

    const handlePageChange = (newPage) => {
        setFilters((prev) => ({ ...prev, page: newPage }));
    };


    return (
        <div className="w-full  p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">

            <div className="flex justify-between">
                <div className="flex flex-col text-left">
                    <h1 className="text-xl text-black font-bold">Prize Claim verification</h1>
                    <h1 className="text-md text-gray-400">This is a list of latest transactions</h1>
                </div>
                <div>
                    <a href="/claims">
                        <p className="text-md text-blue-400">View All</p>
                    </a>
                </div>
            </div>

            <div className="m-4 md:m-0 md:mt-3 relative overflow-x-auto  sm:rounded-lg mt-5">
                {/* Filters UI */}
                <div className="mb-4 md:flex gap-4">
                    <select
                        value={filters.gameType}
                        onChange={(e) => handleFilterChange("gameType", e.target.value)}
                        className="my-1 md:my-0 flex border-2 rounded-full px-3 py-2 w-1/2"
                    >
                        <option value="">All Game Types</option>
                        <option value="Weekly game - National game">Weekly game - National game</option>
                        <option value="Weekly game - Continental Game">Weekly game - Continental Game</option>
                        <option value="Daily game  - Morning">Daily game  - Morning</option>
                        <option value="Daily game  - Afternoon">Daily game  - Afternoon</option>
                        <option value="Daily game  - Evening">Daily game  - Evening</option>
                    </select>
                    <input
                        type="text"
                        placeholder="Search by Winner Name"
                        value={filters.winnerName}
                        onChange={(e) => handleFilterChange("winnerName", e.target.value)}
                        className="my-1 md:my-0 flex border-2 rounded-full px-3 py-2"
                    />
                    <p className="flex">Start Date:</p>
                    <input
                        type="date"
                        placeholder="Search by Start Date"
                        value={filters.startDate}
                        onChange={(e) => handleFilterChange("startDate", e.target.value)}
                        className="my-1 md:my-0 flex border-2 rounded-full px-3 py-2"
                    />
                    <p className="flex">End Date:</p>
                    <input
                        type="date"
                        placeholder="Search by End Date"
                        value={filters.endDate}
                        onChange={(e) => handleFilterChange("endDate", e.target.value)}
                        className="my-1 md:my-0 flex border-2 rounded-full px-3 py-2"
                    />
                    <select
                        value={filters.claimStatus}
                        onChange={(e) => handleFilterChange("claimStatus", e.target.value)}
                        className="my-1 md:my-0 flex border-2 rounded-full px-3 py-2"
                    >
                        <option value="">All Statuses</option>
                        <option value="completed">Completed</option>
                        <option value="in-progress">In Progress</option>
                        <option value="cancelled">Cancelled</option>
                        <option value="0">Pending</option>
                    </select>
                </div>
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="px-6 py-3">
                                Game Type
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Barcode Front
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Transaction
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Date & Time
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Amount
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Status
                            </th>
                            {!isDashboard && permissionModule.canUpdate ? <th scope="col" className="px-6 py-3">

                            </th> : ''}


                        </tr>
                    </thead>
                    <tbody>
                        {
                            prizeClaims.length > 0 ? (
                                permissionModule.canRead ? prizeClaims.map((prizeClaim) => (


                                    <tr key={prizeClaim.id} className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                                        <td className="px-6 py-4">{prizeClaim.gameType}</td>
                                        <td className="px-6 py-4">{prizeClaim.Scratchcard.barcodeFront}</td>
                                        <td className="px-6 py-4">Request from {prizeClaim.User.name}</td>
                                        <td className="px-6 py-4">{formatDate(prizeClaim.createdAt)}</td>
                                        <td className="px-6 py-4">₦{prizeClaim.prizeAmount}</td>
                                        <td className="px-6 py-4">{renderStatus(prizeClaim.claimStatus)}</td>
                                        {!isDashboard && permissionModule.canUpdate ? <td className="px-6 py-4">
                                            <button type="button" onClick={() => getClaimData(prizeClaim.id)} className="text-white py-1 px-4 bg-black rounded-full">Edit</button>
                                        </td> : ''}

                                    </tr>
                                )) : "Access Denied") :
                                (
                                    <tr>
                                        <td colSpan="7" className="text-center py-4">
                                            No Prize Claims found.
                                        </td>
                                    </tr>
                                )
                        }

                    </tbody>
                </table>
                {/* Pagination */}
                <div className="flex justify-center mt-4">
                    <button
                        onClick={() => handlePageChange(filters.page - 1)}
                        disabled={filters.page === 1}
                        className="text-white py-1 px-4 bg-black rounded-full mx-1"
                    >
                        Previous
                    </button>
                    <span className="px-4 py-2 mx-1 font-medium">
                        Page {pagination.currentPage} of {pagination.totalPages}
                    </span>
                    <button
                        onClick={() => handlePageChange(filters.page + 1)}
                        disabled={filters.page === pagination.totalPages}
                        className="text-white py-1 px-4 bg-black rounded-full mx-1"
                    >
                        Next
                    </button>
                </div>
            </div>

            <div
                id="popup-modal"
                tabIndex={-1}
                className="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
            >
                <div className="relative p-4 w-full max-w-4xl max-h-[60%]">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <button
                            type="button"
                            className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                            onClick={() => {
                                const modalElement = document.getElementById("popup-modal");
                                if (modalElement) {
                                    const modal = new Modal(modalElement);
                                    modal.hide();
                                }
                            }}
                        >
                            <svg
                                className="w-3 h-3"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 14 14"
                            >
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                        <div className="p-4  text-center">
                            <div className=" p-6 bg-white shadow rounded-lg">
                                <h2 className="text-2xl font-bold text-center mb-6">Claim Prize</h2>
                                <form onSubmit={handleUpdateClaim}>
                                    <div className="grid grid-cols-1 md:grid-cols-3 gap-2 text-left">

                                        <div className="md:col-span-1">
                                            <label className="block mb-1 font-medium">Game Type</label>
                                        </div>
                                        <div className="md:col-span-2">
                                            <select
                                                id="status"
                                                className="w-full p-2 border border-gray-300 rounded"
                                                value={gameType}
                                                onChange={(e) => setGametype(e.target.value)}
                                                required
                                            >
                                                <option value="" disabled>-- SELECT GAMETYPE --</option>
                                                <option value="Weekly game - National game">Weekly game - National game</option>
                                                <option value="Weekly game - Continental Game">Weekly game - Continental Game</option>
                                                <option value="Daily game  - Morning">Daily game  - Morning</option>
                                                <option value="Daily game  - Afternoon">Daily game  - Afternoon</option>
                                                <option value="Daily game  - Evening">Daily game  - Evening</option>
                                            </select>

                                        </div>

                                        <div className="md:col-span-1">
                                            <label className="block mb-1 font-medium">Kiosk Name</label>
                                        </div>
                                        <div className="md:col-span-2">
                                            <input
                                                type="text"
                                                className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                placeholder="David"
                                                value={kioskname}
                                                onChange={(e) => setKioskname(e.target.value)}

                                            />
                                        </div>




                                        <div className="md:col-span-1">
                                            <label className="block mb-1 font-medium">Prize Money</label>
                                        </div>
                                        <div className="md:col-span-2">
                                            <input
                                                type="text"
                                                className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                placeholder={30000}
                                                value={prizeamt}
                                                onChange={(e) => setPrizeamt(e.target.value)}
                                                required
                                            />
                                        </div>

                                        <div className="md:col-span-1">
                                            <label className="block mb-1 font-medium">Winner&apos;s Name</label>
                                        </div>
                                        <div className="md:col-span-2">
                                            <input
                                                type="text"
                                                className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                placeholder="Demo demo"
                                                value={username}
                                                onChange={(e) => setUsername(e.target.value)}

                                            />
                                        </div>

                                        <div className="md:col-span-1">
                                            <label className="block mb-1 font-medium">Contact Number</label>
                                        </div>
                                        <div className="md:col-span-2">
                                            <input
                                                type="text"
                                                className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                placeholder="+23 77777777777"
                                                value={contact}
                                                onChange={(e) => setContact(e.target.value)}
                                                required
                                            />
                                        </div>

                                        <div className="md:col-span-1">
                                            <label className="block mb-1 font-medium">Account Number</label>
                                        </div>
                                        <div className="md:col-span-2">
                                            <input
                                                type="text"
                                                className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                placeholder={22222222}
                                                value={account}
                                                onChange={(e) => setAccount(e.target.value)}
                                                required
                                            />
                                        </div>

                                        <div className="md:col-span-1">
                                            <label className="block mb-1 font-medium">Sort Code</label>
                                        </div>
                                        <div className="md:col-span-2">
                                            <input
                                                type="text"
                                                className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                placeholder="000-000-000-00"
                                                value={sortcode}
                                                onChange={(e) => setSortcode(e.target.value)}
                                                required
                                            />
                                        </div>

                                        <div className="md:col-span-1">
                                            <label className="block mb-1 font-medium">Status</label>
                                        </div>
                                        <div className="md:col-span-2">
                                            <select
                                                id="status"
                                                className="w-full p-2 border border-gray-300 rounded"
                                                value={status}
                                                onChange={(e) => setStatus(e.target.value)}
                                                required
                                            >
                                                <option value="0">Pending</option>
                                                <option value="completed">Completed</option>
                                                <option value="in-progress">In Progress</option>
                                                <option value="cancelled">Cancelled</option>
                                            </select>
                                            <p className="mt-2 text-sm">Selected Status: {status || "Pending"}</p>
                                        </div>

                                    </div>
                                    <div className="mt-5 flex justify-end">

                                        <button
                                            type="submit"
                                            className="text-white bg-black rounded-full text-sm inline-flex items-center px-5 py-2.5 text-center"
                                        >
                                            Update
                                        </button>
                                        <button
                                            onClick={() => {
                                                const modalElement = document.getElementById("popup-modal");
                                                if (modalElement) {
                                                    const modal = new Modal(modalElement);
                                                    modal.hide();
                                                }
                                            }}
                                            type="button"
                                            className="py-2.5 px-5 ms-3 text-sm font-medium text-black focus:outline-none bg-white rounded-full border border-black hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </form>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PrizeClaim;