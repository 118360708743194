import React, { useEffect, useState } from "react";
import axios from "axios";
import { Modal } from "flowbite";
import toast from 'react-hot-toast'
import { useNavigate } from "react-router-dom";


const Roles = () => {

    const token = sessionStorage.getItem("bearer_token");
    const auth_token = sessionStorage.getItem("token");
    const [roles, setRoles] = useState([]);

    const [refreshKey, setRefreshKey] = useState(0);

    const [roleName, setRoleName] = useState('');
    const [description, setRoleDescription] = useState('');
    const [permissions, setRolePermissions] = useState([]);

    const [id, setId] = useState('');
    const navigate = useNavigate();

    const permission = JSON.parse(sessionStorage.getItem("permissions"));

    const [permissionModule, setPermissionModule] = useState([]);
    



    // create admin
    const handleCreateRole = async (e) => {
        e.preventDefault();

        const postData = {
            "roleName": roleName,
            "description": description,
            "permissions": permissions
        };


        try {
            const res = await axios.post(process.env.REACT_APP_API_URL + "api/role-permission/create-role-assign", postData, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });

            setRefreshKey((prevKey) => prevKey + 1);
            toast.success(res.data.message);



        } catch (err) {
            if (err.response?.status === 400) {
                // Handle invalid token error
                if (err.response.data === "invalid token") {
                    navigate("/login"); // Redirect to login page
                } else {
                    toast.error(err.response.data); // Show other 400 errors
                }
            } else {
                // Handle other errors
                toast.error("An unexpected error occurred.");
            }
        }

    };


    useEffect(() => {
        const getRoles = async () => {

            try {
                const res = await axios.get(process.env.REACT_APP_API_URL + "api/role-permission/get-role-assign", {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`,
                        "auth_token": `${auth_token}`,
                    },
                });

                setRoles(res.data.roles);
                const initial_permission = res.data.roles[0].Permissions.map((permission) => ({
                    "moduleId": permission.moduleId,
                    "canCreate": true,
                    "canRead": true,
                    "canUpdate": true,
                    "canDelete": true,
                    "Module": permission.Module
                }))


                setRolePermissions(initial_permission);


            }
            catch (err) {
                if (err.response?.status === 400) {
                    // Handle invalid token error
                    if (err.response.data === "invalid token") {
                        navigate("/login"); // Redirect to login page
                    } else {
                        toast.error(err.response.data); // Show other 400 errors
                    }
                } else {
                    // Handle other errors
                    toast.error("An unexpected error occurred.");
                }
            }
        }
        getRoles();
        if (permission) {

            setPermissionModule(permission.find(
                (permission) => permission.Module.moduleName === 'role'
            ));
        }

    }, [refreshKey]);



    useEffect(() => {
        // Initialize the modal on component mount
        const modalElement = document.getElementById("edit-modal");
        if (modalElement) {
            const modal = new Modal(modalElement);
            modal.hide(); // Ensure the modal is initially hidden
        }

        // Initialize the modal on component mount
        const createElement = document.getElementById("popup-modal");
        if (createElement) {
            const create_modal = new Modal(createElement);
            create_modal.hide(); // Ensure the modal is initially hidden
        }
    }, []);

    // get single admin data
    const getRoleData = async (id) => {
        const modalElement = document.getElementById("edit-modal");
        if (modalElement) {
            const modal = new Modal(modalElement);
            modal.show();
        }

        try {
            const res = await axios.get(process.env.REACT_APP_API_URL + `api/role-permission/get-role-assign/${id}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });


            setRoleName(res.data.role.roleName);
            setRoleDescription(res.data.role.description);
            setRolePermissions(res.data.role.Permissions);
            setId(id);


        } catch (err) {
            if (err.response?.status === 400) {
                // Handle invalid token error
                if (err.response.data === "invalid token") {
                    navigate("/login"); // Redirect to login page
                } else {
                    toast.error(err.response.data); // Show other 400 errors
                }
            } else {
                // Handle other errors
                toast.error("An unexpected error occurred.");
            }
        }
    }


    // update single admin data
    const handleUpdateRole = async (e) => {
        e.preventDefault();
        const update_id = id;


        const postData = {
            "roleName": roleName,
            "description": description,
            "permissions": permissions
        };

        try {

            const res = await axios.put(process.env.REACT_APP_API_URL + `api/role-permission/update-role-assign/${update_id}`, postData, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });
            setRefreshKey((prevKey) => prevKey + 1);
            toast.success(res.data.message);



        } catch (err) {
            if (err.response?.status === 400) {
                // Handle invalid token error
                if (err.response.data === "invalid token") {
                    navigate("/login"); // Redirect to login page
                } else {
                    toast.error(err.response.data); // Show other 400 errors
                }
            } else {
                // Handle other errors
                toast.error("An unexpected error occurred.");
            }
        }

    };

    const deleteRole = async (id) => {

        try {
            const res = await axios.delete(process.env.REACT_APP_API_URL + `api/role-permission/delete-role-assign/${id}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });
            setRefreshKey((prevKey) => prevKey + 1);

            toast.success(res.data.message);




        } catch (err) {
            if (err.response?.status === 400) {
                // Handle invalid token error
                if (err.response.data === "invalid token") {
                    navigate("/login"); // Redirect to login page
                } else {
                    toast.error(err.response.data); // Show other 400 errors
                }
            } else {
                // Handle other errors
                toast.error("An unexpected error occurred.");
            }
        }
    }

    const updateEditingPermission = (
        moduleId,
        field,
        value
    ) => {
        const updatedPermissions = permissions.map((perm) =>
            perm.moduleId === moduleId ? { ...perm, [field]: value } : perm
        );

        setRolePermissions(updatedPermissions);
    };

    return (
        <div className="bg-gray-100">
            <div className="max-w-7xl mx-auto py-20 p-4 sm:ml-64">

                <ul className="flex md:w-1/2 mt-5 text-md text-black bg-[#F1F5F9]  rounded-lg overflow-hidden shadow-sm">
                    <li className="flex-1">
                        <a
                            href="/admins"
                            className="block m-1 p-2 text-md text-center text-gray-500 rounded-lg shadow-sm"

                        >
                            Admins
                        </a>
                    </li>
                    <li className="flex-1">
                        <a
                            href="/roles"
                            className="block m-1 p-2 text-center  bg-white text-black hover:text-black"
                            aria-current="page"
                        >
                            Manage Roles
                        </a>
                    </li>
                    <li className="flex-1">
                        <a
                            href="/modules"
                            className="block m-1 p-2 text-md text-center text-gray-500 rounded-lg shadow-sm"
                            aria-current="page"
                        >
                            Modules
                        </a>
                    </li>
                </ul>


                {
                    permissionModule.canCreate &&
                    <button type="button"
                        onClick={() => {
                            const createElement = document.getElementById("popup-modal");
                            if (createElement) {
                                const create_modal = new Modal(createElement);
                                create_modal.show();
                            }
                        }}
                        className="flex text-white bg-black rounded-full px-5 py-2 my-5">Add New Role</button>
                }


                <div className="w-full  p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">

                    <div className="flex justify-between">
                        <div className="flex flex-col text-left">
                            <h1 className="text-xl text-black font-bold">Roles</h1>
                            <h1 className="text-md text-gray-400">This is a list of roles with permissions</h1>
                        </div>
                        <div>
                            <button type="button" data-collapse-toggle="navbar-search" aria-controls="navbar-search" aria-expanded="false" class="md:hidden text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5 me-1">
                                <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                </svg>
                                <span class="sr-only">Search</span>
                            </button>
                            <div class="relative hidden md:block">
                                <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                    <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                    </svg>
                                    <span class="sr-only">Search icon</span>
                                </div>
                                <input type="text" id="search-navbar" className="block w-full p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search..." />
                            </div>
                        </div>
                    </div>

                    <div className="relative overflow-x-auto  sm:rounded-lg mt-5">
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                    <th scope="col" className="px-6 py-3">
                                        id
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Name
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Description
                                    </th>
                                    {
                                        permissionModule.canUpdate &&
                                        <th scope="col" className="px-6 py-3">

                                        </th>
                                    }

                                </tr>
                            </thead>
                            <tbody>

                                {
                                    permissionModule.canRead ? roles.map((role, index) => (
                                        <tr key={role.id} className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                                            <th
                                                scope="row"
                                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                            >
                                                {index + 1}
                                            </th>
                                            <td className="px-6 py-4">{role.roleName}</td>
                                            <td className="px-6 py-4">{role.description}</td>

                                            {
                                                permissionModule.canUpdate &&
                                                <td className="px-6 py-4">
                                                    <button type="button" onClick={() => getRoleData(role.id)} className="text-white py-1 px-4 bg-black rounded-full">Edit</button>
                                                </td>
                                            }

                                        </tr>
                                    )) : "Access Denied"
                                }

                            </tbody>
                        </table>
                    </div>

                    <div
                        id="popup-modal"
                        tabIndex={-1}
                        className="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
                    >
                        <div className="relative p-4 w-full max-w-4xl max-h-[60%]">
                            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                <button
                                    type="button"
                                    className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                    onClick={() => {
                                        const createElement = document.getElementById("popup-modal");
                                        if (createElement) {
                                            const create_modal = new Modal(createElement);
                                            create_modal.hide();
                                        }
                                    }}
                                >
                                    <svg
                                        className="w-3 h-3"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 14 14"
                                    >
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                        />
                                    </svg>
                                    <span className="sr-only">Close modal</span>
                                </button>
                                <div className="p-4  text-center">
                                    <div className=" p-6 bg-white shadow rounded-lg">
                                        <h2 className="text-2xl font-bold text-center mb-6">Add Role</h2>
                                        <form onSubmit={handleCreateRole}>
                                            <div className="grid grid-cols-1 md:grid-cols-3 gap-2 text-left">

                                                <div className="md:col-span-1">
                                                    <label className="block mb-1 font-medium">Role Name</label>
                                                </div>
                                                <div className="md:col-span-2">
                                                    <input
                                                        type="text"
                                                        className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                        placeholder="Super Admin"
                                                        value={roleName}
                                                        onChange={(e) => setRoleName(e.target.value)}
                                                        required
                                                    />
                                                </div>

                                                <div className="md:col-span-1">
                                                    <label className="block mb-1 font-medium">Role Description</label>
                                                </div>
                                                <div className="md:col-span-2">
                                                    <input
                                                        type="text"
                                                        className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                        placeholder="Super Admin with all permissions"
                                                        value={description}
                                                        onChange={(e) => setRoleDescription(e.target.value)}
                                                        required
                                                    />
                                                </div>

                                                <div className="md:col-span-3">
                                                    <label className="block mb-1 font-medium">Permissions</label>
                                                </div>
                                                <div className="md:col-span-3 overflow-x-auto">
                                                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                                        <thead className="text-center text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                                            <tr>
                                                                <th scope="col" className="text-left px-6 py-3">
                                                                    Module Name
                                                                </th>
                                                                <th scope="col" className="px-6 py-3">
                                                                    Can Read
                                                                </th>
                                                                <th scope="col" className="px-6 py-3">
                                                                    Can Create
                                                                </th>
                                                                <th scope="col" className="px-6 py-3">
                                                                    Can Update
                                                                </th>
                                                                <th scope="col" className="px-6 py-3">
                                                                    Can Delete
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {permissions.map((permission, index) => (
                                                                <tr key={permission.Module.id} className="text-center odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                                                                    <th
                                                                        scope="row"
                                                                        className="text-left px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                                                    >
                                                                        {permission.Module.description}
                                                                    </th>
                                                                    <td className="px-6 py-4">
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={permission.canRead}
                                                                            onChange={(e) => updateEditingPermission(permission.Module.id, "canRead", e.target.checked)}
                                                                        />
                                                                    </td>

                                                                    <td className="px-6 py-4">
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={permission.canCreate}
                                                                            onChange={(e) => updateEditingPermission(permission.Module.id, "canCreate", e.target.checked)}
                                                                        />
                                                                    </td>

                                                                    <td className="px-6 py-4">
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={permission.canUpdate}
                                                                            onChange={(e) => updateEditingPermission(permission.Module.id, "canUpdate", e.target.checked)}
                                                                        />
                                                                    </td>

                                                                    <td className="px-6 py-4">
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={permission.canDelete}
                                                                            onChange={(e) => updateEditingPermission(permission.Module.id, "canDelete", e.target.checked)}
                                                                        />
                                                                    </td>


                                                                </tr>
                                                            ))}


                                                        </tbody>
                                                    </table>
                                                </div>




                                            </div>

                                            <div className="mt-5 flex justify-between">

                                                {/* <div>
                                                    <button className="bg-red-600 text-white px-5 py-2 rounded-full">Delete User</button>
                                                </div> */}

                                                <div>

                                                    <button
                                                        onClick={() => {
                                                            const createElement = document.getElementById("popup-modal");
                                                            if (createElement) {
                                                                const create_modal = new Modal(createElement);
                                                                create_modal.hide();
                                                            }
                                                        }}
                                                        type="submit"
                                                        className="text-white bg-black rounded-full text-sm inline-flex items-center px-5 py-2.5 text-center"
                                                    >
                                                        Save
                                                    </button>
                                                    <button
                                                        onClick={() => {
                                                            const createElement = document.getElementById("popup-modal");
                                                            if (createElement) {
                                                                const create_modal = new Modal(createElement);
                                                                create_modal.hide();
                                                            }
                                                        }}
                                                        type="button"
                                                        className="py-2.5 px-5 ms-3 text-sm font-medium text-black focus:outline-none bg-white rounded-full border border-black hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>


                    {/* edit modal */}
                    <div
                        id="edit-modal"
                        tabIndex={-1}
                        className="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
                    >
                        <div className="relative p-4 w-full max-w-4xl max-h-[60%]">
                            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                <button
                                    type="button"
                                    className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                    onClick={() => {
                                        const modalElement = document.getElementById("edit-modal");
                                        if (modalElement) {
                                            const modal = new Modal(modalElement);
                                            modal.hide();
                                        }
                                    }}
                                >
                                    <svg
                                        className="w-3 h-3"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 14 14"
                                    >
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                        />
                                    </svg>
                                    <span className="sr-only">Close modal</span>
                                </button>
                                <div className="p-4  text-center">
                                    <div className=" p-6 bg-white shadow rounded-lg">
                                        <h2 className="text-2xl font-bold text-center mb-6">Edit Role</h2>
                                        <form onSubmit={handleUpdateRole}>
                                            <div className="grid grid-cols-1 md:grid-cols-3 gap-2 text-left">

                                                <div className="md:col-span-1">
                                                    <label className="block mb-1 font-medium">Role Name</label>
                                                </div>
                                                <div className="md:col-span-2">
                                                    <input
                                                        type="text"
                                                        className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                        placeholder="Super Admin"
                                                        value={roleName}
                                                        onChange={(e) => setRoleName(e.target.value)}
                                                        required
                                                    />
                                                </div>

                                                <div className="md:col-span-1">
                                                    <label className="block mb-1 font-medium">Role Description</label>
                                                </div>
                                                <div className="md:col-span-2">
                                                    <input
                                                        type="text"
                                                        className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                        placeholder="Super Admin with all permissions"
                                                        value={description}
                                                        onChange={(e) => setRoleDescription(e.target.value)}
                                                        required
                                                    />
                                                </div>

                                                <div className="md:col-span-3">
                                                    <label className="block mb-1 font-medium">Permissions</label>
                                                </div>
                                                <div className="md:col-span-3 overflow-x-auto">

                                                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                                        <thead className="text-xs text-center text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                                            <tr>
                                                                <th scope="col" className="text-left px-6 py-3">
                                                                    Module Name
                                                                </th>
                                                                <th scope="col" className="px-6 py-3">
                                                                    Can Read
                                                                </th>
                                                                <th scope="col" className="px-6 py-3">
                                                                    Can Create
                                                                </th>
                                                                <th scope="col" className="px-6 py-3">
                                                                    Can Update
                                                                </th>
                                                                <th scope="col" className="px-6 py-3">
                                                                    Can Delete
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {permissions.map((permission, index) => (
                                                                <tr key={index} className="text-center odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                                                                    <th
                                                                        scope="row"
                                                                        className="text-left px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                                                    >
                                                                        {permission.Module.description}
                                                                    </th>
                                                                    <td className="px-6 py-4">
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={permission.canRead}
                                                                            onChange={(e) => updateEditingPermission(permission.Module.id, "canRead", e.target.checked)}
                                                                        />
                                                                    </td>

                                                                    <td className="px-6 py-4">
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={permission.canCreate}
                                                                            onChange={(e) => updateEditingPermission(permission.Module.id, "canCreate", e.target.checked)}
                                                                        />
                                                                    </td>

                                                                    <td className="px-6 py-4">
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={permission.canUpdate}
                                                                            onChange={(e) => updateEditingPermission(permission.Module.id, "canUpdate", e.target.checked)}
                                                                        />
                                                                    </td>

                                                                    <td className="px-6 py-4">
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={permission.canDelete}
                                                                            onChange={(e) => updateEditingPermission(permission.Module.id, "canDelete", e.target.checked)}
                                                                        />
                                                                    </td>


                                                                </tr>
                                                            ))}


                                                        </tbody>
                                                    </table>


                                                </div>

                                            </div>


                                            <div className="mt-5 flex justify-end">



                                                <div>

                                                    <button
                                                        onClick={() => {
                                                            const createElement = document.getElementById("popup-modal");
                                                            if (createElement) {
                                                                const create_modal = new Modal(createElement);
                                                                create_modal.hide();
                                                            }
                                                        }}
                                                        type="submit"
                                                        className="text-white bg-black rounded-full text-sm inline-flex items-center px-5 py-2.5 text-center"
                                                    >
                                                        Save
                                                    </button>
                                                    <button
                                                        onClick={() => {
                                                            const modalElement = document.getElementById("edit-modal");
                                                            if (modalElement) {
                                                                const modal = new Modal(modalElement);
                                                                modal.hide();
                                                            }
                                                        }}
                                                        type="button"
                                                        className="py-2.5 px-5 ms-3 text-sm font-medium text-black focus:outline-none bg-white rounded-full border border-black hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </form>

                                        {
                                            permissionModule.canDelete &&
                                            <div className="flex justify-start ">
                                                <button onClick={() => deleteRole(id)} className="bg-red-600 text-white px-5 py-2 rounded-full">Remove</button>
                                            </div>
                                        }
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Roles;