import React, { useEffect, useState } from "react";
import ApexCharts from "apexcharts";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

const Chart = () => {
  const location = useLocation();
  const token = sessionStorage.getItem("bearer_token");
  const auth_token = sessionStorage.getItem("token");
  const [dates, setDates] = useState([]);
  const [totalSalesValues, setTotalSalesValues] = useState([]);
  const navigate = useNavigate();

  // Generate the last 7 days
  const getLast7Days = () => {
    const today = new Date();
    const datesArray = [];

    for (let i = 0; i < 7; i++) {
      const date = new Date();
      date.setDate(today.getDate() - i);
      const formattedDate = date.toISOString().split("T")[0];
      datesArray.push(formattedDate);
    }

    return datesArray.reverse(); // Oldest to newest order
  };

  // Fetch sales data
  const getSalesData = async () => {
    try {
      const endpoint =
        location.pathname === "/kiosk-sales"
          ? "api/sales/week-report?kiosk=true"
          : "api/sales/week-report";
      const res = await axios.get(process.env.REACT_APP_API_URL + endpoint, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          auth_token: `${auth_token}`,
        },
      });

      const salesDataMap = res.data.data.reduce((acc, entry) => {
        acc[entry.date] = parseFloat(entry.totalSalesValue);
        return acc;
      }, {});

      const totalSalesValuesArray = dates.map((date) =>
        salesDataMap[date] !== undefined ? salesDataMap[date] : 0
      );

      setTotalSalesValues(totalSalesValuesArray);
    } catch (err) {
      if (err.response?.status === 400 && err.response.data === "invalid token") {
        navigate("/login");
      } else {
        // toast.error(err.response?.data || "An error occurred");
        setTotalSalesValues(Array(7).fill(0));
      }
    }
  };
  

  // Initialize and update chart
  const updateChart = () => {
    const options = {
      series: [
        {
          name: "Revenue",
          data: totalSalesValues,
          color: "#1A56DB",
        },
      ],
      xaxis: {
        categories: dates,
        labels: {
          style: {
            fontFamily: "Inter, sans-serif",
            cssClass: "text-xs font-normal fill-gray-500 dark:fill-gray-400",
          },
        },
      },
      yaxis: {
        min: 0,
        max: Math.max(...totalSalesValues) + 100000,
        tickAmount: 6,
        labels: {
          formatter: (value) => `${(value / 1000).toFixed(2)}K`,
        },
      },
      chart: {
        type: "area",
        height: "400",
        animations: { enabled: true },
      },
      
      fill: {
        type: "gradient",
        gradient: {
          opacityFrom: 0.6,
          opacityTo: 0,
          shade: "light",
          gradientToColors: ["#1C64F2"],
        },
      },
      stroke: {
        width: 3,
        curve: "smooth",
      },
      grid: {
        borderColor: "#E5E7EB",
        strokeDashArray: 5,
      },
    };

    const chartElement = document.getElementById("labels-chart");
    if (chartElement) {
      const chart = new ApexCharts(chartElement, options);
      chart.render();

      return () => chart.destroy(); // Clean up chart
    }
  };

  useEffect(() => {
    setDates(getLast7Days());
  }, []);

  useEffect(() => {
    if (dates.length > 0) getSalesData();
  }, [dates]);

  useEffect(() => {
    if (totalSalesValues.length > 0) updateChart();
  }, [totalSalesValues]);

  return <div id="labels-chart"></div>;
};

export default Chart;
