import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal } from "flowbite";
import toast from 'react-hot-toast'
import { useNavigate } from "react-router-dom";

const UploadTest = () => {

    const [batches, setBatches] = useState([]);

    const [file, setFile] = useState(null);

    const navigate = useNavigate();

    const [refreshKey, setRefreshKey] = useState(0);
    const [batchId, setBatchId] = useState('');
    const [filename, setFileName] = useState('');
    const [userId, setUserId] = useState('');

    const [searchTerm, setSearchTerm] = useState('');

    const token = sessionStorage.getItem("bearer_token");
    const auth_token = sessionStorage.getItem("token");

    const permissions = JSON.parse(sessionStorage.getItem("permissions"));

    const [permissionModule, setPermissionModule] = useState([]);

    const [loading, setLoading] = useState(false);

    // pagination and search
    const [filters, setFilters] = useState({
        batch: "",
        status: "",
        startDate: "",
        endDate: "",
        page: 1,
        limit: 10,
    });
    const [pagination, setPagination] = useState({
        totalRecords: 0,
        currentPage: 1,
        totalPages: 0,
        limit: 0,
    });


    useEffect(() => {

        // Initialize the modal on component mount
        const createElement = document.getElementById("popup-modal");
        if (createElement) {
            const create_modal = new Modal(createElement);
            create_modal.hide(); // Ensure the modal is initially hidden
        }
        if (permissions) {

            setPermissionModule(permissions.find(
                (permission) => permission.Module.moduleName === 'scratchcardsUpload'
            ));
        }
    }, []);

    useEffect(() => {
        const getBatch = async (e) => {

            try {
                const queryParams = Object.entries(filters)
                    .filter(([_, value]) => value) // Only include parameters with non-empty values
                    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
                    .join("&");
                const res = await axios.get(process.env.REACT_APP_API_URL + `api/batch/get-batches?${queryParams}`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`,
                        "auth_token": `${auth_token}`,
                    },
                });

                setBatches(res.data.data.batches);
                setPagination({
                    totalRecords: res.data.data.pagination.totalRecords,
                    currentPage: res.data.data.pagination.currentPage,
                    totalPages: res.data.data.pagination.totalPages,
                    limit: res.data.data.pagination.limit,
                });

            } catch (err) {
                if (err.response?.status === 400) {
                    // Handle invalid token error
                    if (err.response.data === "invalid token") {
                        navigate("/login"); // Redirect to login page
                    } else {
                        toast.error(err.response.data); // Show other 400 errors
                    }
                } else {
                    // Handle other errors
                    toast.error("No Batches found.");
                }
            }
        }
        getBatch();


    }, [filters, refreshKey]);

    const formatDate = (dateString) => {
        const date = new Date(dateString.replace(" ", "T")); // Replace space with T for compatibility
        const options = { month: 'long', day: 'numeric', year: 'numeric' };
        const formattedDate = date.toLocaleDateString('en-US', options);
        const time = date.toLocaleTimeString('en-US', { hour12: false });
        return `${formattedDate} ${time}`;
    };


    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
        setFileName(event.target.files[0].name);
        toast.success("File Uploaded successfully");
    };

    const handleIdChange = (event) => {

        const user_id = batches.find((batch) => batch.id === event.target.value);
        setUserId(user_id.allocatedTo);
        setBatchId(event.target.value); // Update ID from input field
    };

    const handleUpload = (e) => {
        e.preventDefault();
    
        if (!file) {
            toast.error("Please select a file.");
            return;
        }
        if (!batchId) {
            toast.error("Please select a batch.");
            return;
        }
        if (!userId) {
            toast.error("User not found.");
            return;
        }
    
        const formData = new FormData();
        formData.append('file', file);
        formData.append('userId', userId);
        formData.append('batchId', batchId);
    
        setLoading(true); // Start loading process
    
        const uploadFile = () => {
            return new Promise((resolve, reject) => {
                axios.post(process.env.REACT_APP_API_URL + 'api/scratch-upload/upload-scratchcards', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        "Authorization": `Bearer ${token}`,
                        "auth_token": `${auth_token}`,
                    },
                })
                    .then((res) => {
                        if (res.status === 200) {
                            resolve(res.data);
                        } else {
                            reject(new Error("Unexpected status code"));
                        }
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        };
    
        uploadFile()
            .then((data) => {
                toast.success(data.message);
            })
            .catch((err) => {
                if (err.response?.status === 400) {
                    if (err.response.data === "invalid token") {
                        navigate("/login"); // Redirect to login page
                    } else {
                        toast.error(err.response.data); // Show other 400 errors
                    }
                } else {
                    toast.error("An unexpected error occurred.");
                }
            })
            .finally(() => {
                setLoading(false); // End loading process
            });
    };

    //  update status of user:
    const updateStatus = async (id, status) => {
        const postData = {
            "status": status,
        }
        try {

            const res = await axios.put(process.env.REACT_APP_API_URL + `api/batch/update-batch/${id}`, postData, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });
            toast.success(res.data.message);
            setRefreshKey((prevKey) => prevKey + 1);

        } catch (err) {
            if (err.response?.status === 400) {
                // Handle invalid token error
                if (err.response.data === "invalid token") {
                    navigate("/login"); // Redirect to login page
                } else {
                    toast.error(err.response.data); // Show other 400 errors
                }
            } else {
                // Handle other errors
                toast.error("An unexpected error occurred.");
            }
        }
    }

    // search and pagination
    const handleFilterChange = (key, value) => {
        setFilters((prev) => ({
            ...prev,
            [key]: value,
            page: 1, // Reset to the first page on filter change
        }));
    };

    const handlePageChange = (newPage) => {
        setFilters((prev) => ({ ...prev, page: newPage }));
    };

    // Handle search functionality
    const handleSearch = async (search) => {
        setSearchTerm(search);


        try {

            const res = await axios.get(process.env.REACT_APP_API_URL + `api/batch/get-batches?batch=${search}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });
            if (res.data.success) {
                setBatches(res.data.data.batches);
            }


        } catch (err) {
            if (err.response?.status === 400) {
                // Handle invalid token error
                if (err.response.data === "invalid token") {
                    navigate("/login"); // Redirect to login page
                } else {
                    toast.error(err.response.data); // Show other 400 errors
                }
            } else {
                // Handle other errors
                toast.error("Batch not found");
            }
        }

    };

    return (
        <div className="bg-gray-100">
            <div className="max-w-7xl mx-auto py-20 p-4 sm:ml-64">
                <ul className="flex md:w-1/2 mt-5 text-md text-black bg-[#F1F5F9]  rounded-lg overflow-hidden shadow-sm">
                    <li className="flex-1">
                        <a
                            href="/add-batch"
                            className="block m-1 p-2 text-md text-center bg-white text-black rounded-lg shadow-sm"
                            aria-current="page"
                        >
                            Batches
                        </a>
                    </li>
                    <li className="flex-1">
                        <a
                            href="/track"
                            className="block m-1 p-2 text-center text-gray-500 hover:text-black"
                        >
                            Add Allocation
                        </a>
                    </li>
                </ul>

                <div className="md:flex justify-between">

                    {
                        permissionModule.canCreate &&
                        <button type="button"
                            onClick={() => {
                                const createElement = document.getElementById("popup-modal");
                                if (createElement) {
                                    const create_modal = new Modal(createElement);
                                    create_modal.show();
                                }
                            }}
                            className="flex text-white bg-black rounded-full px-5 py-2 my-5">Add a new batch</button>

                    }

                    {/* Filters UI */}
                    <div className=" items-center md:flex gap-4">
                        <input
                            type="text"
                            placeholder="Search by Batch Name"
                            value={filters.batch}
                            onChange={(e) => handleFilterChange("batch", e.target.value)}
                            className="my-1 md:my-0 flex border-2 rounded-full px-3 py-2"
                        />
                        <p className="flex">Start Date:</p>
                        <input
                            type="date"
                            placeholder="Search by Start Date"
                            value={filters.startDate}
                            onChange={(e) => handleFilterChange("startDate", e.target.value)}
                            className="my-1 md:my-0 flex border-2 rounded-full px-3 py-2"
                        />
                        <p className="flex">End Date:</p>
                        <input
                            type="date"
                            placeholder="Search by End Date"
                            value={filters.endDate}
                            onChange={(e) => handleFilterChange("endDate", e.target.value)}
                            className="my-1 md:my-0 flex border-2 rounded-full px-3 py-2"
                        />


                        <select
                            value={filters.status}
                            onChange={(e) => handleFilterChange("status", e.target.value)}
                            className="my-1 md:my-0 flex border-2 rounded-full px-3 py-2"
                        >
                            <option value="">All Statuses</option>
                            <option value="1">Active</option>
                            <option value="0">Inactive</option>
                        </select>
                    </div>

                </div>

                <div className="w-full  p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">

                    <div className="flex justify-start">
                        <div className="flex flex-col text-left">
                            <h1 className="text-xl text-black font-bold">Batches</h1>
                            <h1 className="text-md text-gray-400">Add new lottery batches</h1>
                        </div>

                    </div>

                    <div className="relative overflow-x-auto  sm:rounded-lg mt-5">

                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                    <th scope="col" className="px-6 py-3">
                                        Batch
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Date & Time
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Updated By
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Allocated To
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Status
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Toggle Status
                                    </th>


                                </tr>
                            </thead>
                            <tbody>
                                {
                                    batches.length > 0 ? (
                                        permissionModule.canRead ? batches.map((batch) => (
                                            <tr key={batch.id} className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                                                <th
                                                    scope="row"
                                                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                                >
                                                    {batch.batch}
                                                </th>
                                                <td className="px-6 py-4">{formatDate(batch.date)}</td>
                                                <td className="px-6 py-4">{batch.AdminUser.name}</td>
                                                <td className="px-6 py-4">{batch.User.name}</td>
                                                <td className="px-6 py-4">
                                                    {batch.status === true ? <p className="bg-[#DEF7EC] text-sm text-[#03543F] px-3 py-1 rounded-full md:w-full text-center">Active</p> : <p className="bg-[#FBD5D5] text-sm text-red-500 px-3 py-1 rounded-full md:w-full text-center">Inactive</p>}

                                                </td>
                                                <td className="px-6 py-4">
                                                    {
                                                        batch.status === true ? <button type="button" onClick={() => updateStatus(batch.id, "false")} className="bg-red-600 text-white px-3 py-1 rounded-full">Disable</button>
                                                            : <button type="button" onClick={() => updateStatus(batch.id, true)} className="bg-green-600 text-white px-3 py-1 rounded-full">Enable</button>
                                                    }
                                                </td>


                                            </tr>
                                        )) : "Access Denied") :
                                        (
                                            <tr>
                                                <td colSpan="7" className="text-center py-4">
                                                    No batches found.
                                                </td>
                                            </tr>
                                        )
                                }

                            </tbody>
                        </table>
                        {/* Pagination */}
                        <div className="flex justify-center mt-4">
                            <button
                                onClick={() => handlePageChange(filters.page - 1)}
                                disabled={filters.page === 1}
                                className="text-white py-1 px-4 bg-black rounded-full mx-1"
                            >
                                Previous
                            </button>
                            <span className="px-4 py-2 mx-1 font-medium">
                                Page {pagination.currentPage} of {pagination.totalPages}
                            </span>
                            <button
                                onClick={() => handlePageChange(filters.page + 1)}
                                disabled={filters.page === pagination.totalPages}
                                className="text-white py-1 px-4 bg-black rounded-full mx-1"
                            >
                                Next
                            </button>
                        </div>
                    </div>

                    <div
                        id="popup-modal"
                        tabIndex={-1}
                        className="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
                    >
                        <div className="relative p-4 w-full max-w-4xl max-h-[60%]">
                            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                <button
                                    type="button"
                                    className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                    onClick={() => {
                                        const createElement = document.getElementById("popup-modal");
                                        if (createElement) {
                                            const create_modal = new Modal(createElement);
                                            create_modal.hide();
                                        }
                                    }}
                                >
                                    <svg
                                        className="w-3 h-3"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 14 14"
                                    >
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                        />
                                    </svg>
                                    <span className="sr-only">Close modal</span>
                                </button>
                                <div className="p-4  text-center">
                                    <div className=" p-6 bg-white shadow rounded-lg">
                                        <h2 className="text-2xl font-bold text-center mb-6">Add a batch</h2>
                                        <form onSubmit={handleUpload}>
                                            <div className="grid grid-cols-1 md:grid-cols-3 gap-2 text-left">

                                                <div className="col-span-3">
                                                    <div className="flex items-center justify-center w-full">
                                                        <label
                                                            htmlFor="dropzone-file"
                                                            className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50  dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                                                        >
                                                            <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                                                <svg
                                                                    className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                                                                    aria-hidden="true"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    fill="none"
                                                                    viewBox="0 0 20 16"
                                                                >
                                                                    <path
                                                                        stroke="currentColor"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        strokeWidth={2}
                                                                        d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                                                    />
                                                                </svg>

                                                                <p className="text-md text-black dark:text-gray-400">
                                                                    Drag /Drop or upload .csv file here
                                                                </p>
                                                            </div>
                                                            <input id="dropzone-file" type="file" onChange={handleFileChange} className="hidden" />
                                                        </label>
                                                    </div>
                                                    {
                                                        filename &&
                                                        <p className="text-black font-medium">File Uploaded: {filename}</p>
                                                    }

                                                </div>


                                                <div className="md:col-span-1 flex items-center ">
                                                    <label className="block mb-1 font-medium">Batch</label>
                                                </div>
                                                <div className="md:col-span-2">
                                                    <input
                                                        type="text"
                                                        placeholder="Search Batch..."
                                                        value={searchTerm}
                                                        onChange={(e) => handleSearch(e.target.value)}
                                                        className="w-full p-2 mb-2 border border-gray-300 rounded"
                                                    />
                                                    <select
                                                        id="batchId"
                                                        className="w-full p-2 border border-gray-300 rounded"
                                                        value={batchId}
                                                        onChange={handleIdChange}
                                                        required
                                                    >
                                                        <option value="">--Select a Batch--</option>
                                                        {
                                                            batches.map((batch) => (
                                                                <option key={batch.id} value={batch.id}>{batch.batch}</option>
                                                            ))
                                                        }

                                                    </select>
                                                </div>

                                            </div>


                                            <div className="mt-5 flex justify-end">



                                                <div>

                                                    <button

                                                        type="submit"
                                                        className="text-white bg-black rounded-full text-sm inline-flex items-center px-5 py-2.5 text-center"
                                                        disabled={loading}
                                                    >
                                                        {loading ? "Uploading..." : "Upload"}
                                                    </button>
                                                    <button
                                                        onClick={() => {
                                                            const createElement = document.getElementById("popup-modal");
                                                            if (createElement) {
                                                                const create_modal = new Modal(createElement);
                                                                create_modal.hide();
                                                            }
                                                        }}
                                                        type="button"
                                                        className="py-2.5 px-5 ms-3 text-sm font-medium text-black focus:outline-none bg-white rounded-full border border-black hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>


                    {/* edit modal */}
                    {/* <div
                        id="edit-modal"
                        tabIndex={-1}
                        className="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
                    >
                        <div className="relative p-4 w-full max-w-4xl max-h-[60%]">
                            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                <button
                                    type="button"
                                    className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                    data-modal-hide="edit-modal"
                                >
                                    <svg
                                        className="w-3 h-3"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 14 14"
                                    >
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                        />
                                    </svg>
                                    <span className="sr-only">Close modal</span>
                                </button>
                                <div className="p-4  text-center">
                                    <div className=" p-6 bg-white shadow rounded-lg">
                                        <h2 className="text-2xl font-bold text-center mb-6">Edit a batch</h2>
                                        <form>
                                            <div className="grid grid-cols-1 md:grid-cols-3 gap-2 text-left">

                                                <div className="md:col-span-1 flex items-center ">
                                                    <label className="block mb-1 font-medium">Uploaded By</label>
                                                </div>
                                                <div className="md:col-span-2">
                                                    <select className="w-full p-2 border border-gray-300 rounded">
                                                        <option>User 1</option>
                                                        <option>User 2</option>
                                                        <option>User 3</option>
                                                    </select>
                                                </div>

                                                <div className="md:col-span-1 flex items-center ">
                                                    <label className="block mb-1 font-medium">Document</label>
                                                </div>
                                                <div className="md:col-span-2">
                                                    <select className="w-1/2 p-2 border border-gray-300 rounded">
                                                        <option>User 1</option>
                                                        <option>User 2</option>
                                                        <option>User 3</option>
                                                    </select>
                                                    <button className="ml-1 bg-red-600 text-md text-white px-5 py-2 rounded-full">Remove</button>
                                                </div>

                                            </div>


                                            <div className="mt-5 flex justify-end">
                                                <div>

                                                    <button
                                                        data-modal-hide="edit-modal"
                                                        type="button"
                                                        className="text-white bg-black rounded-full text-sm inline-flex items-center px-5 py-2.5 text-center"
                                                    >
                                                        Update
                                                    </button>
                                                    <button
                                                        data-modal-hide="edit-modal"
                                                        type="button"
                                                        className="py-2.5 px-5 ms-3 text-sm font-medium text-black focus:outline-none bg-white rounded-full border border-black hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>


            </div>
        </div>
    )
}

export default UploadTest;