// src/components/Toaster.js
import { Toaster } from 'react-hot-toast';

const CustomToaster = () => {
    return (
        <Toaster
            position="top-right"
            reverseOrder={false}
            toastOptions={{
                style: {
                    padding: '16px',
                    color: '#fff',
                    background: '#333',
                    fontSize: '16px',
                    borderRadius: '8px',
                },
                success: {
                    iconTheme: {
                        primary: '#4caf50',
                        secondary: '#fff',
                    },
                },
                error: {
                    iconTheme: {
                        primary: '#f44336',
                        secondary: '#fff',
                    },
                },
            }}
        />
    );
};

export default CustomToaster;
