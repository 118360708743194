import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { Modal } from "flowbite";
import toast from 'react-hot-toast'
import { useNavigate } from "react-router-dom";

const Admin = () => {

    const [fullname, setFullname] = useState('');
    const [email, setEmail] = useState('');
    const [designation, setDesignation] = useState('');
    const [password, setPassword] = useState('');
    const [reporting, setReporting] = useState('');
    const [refreshKey, setRefreshKey] = useState(0);
    const [result, setResult] = useState(null);
    const [userSearchTerm, setUserSearchTerm] = useState('');
    

    const [permissionRole, setPermissionRole] = useState('');
    const [roles, setRoles] = useState([]);

    const navigate = useNavigate();

    const [id, setId] = useState('');

    const token = sessionStorage.getItem("bearer_token");
    const auth_token = sessionStorage.getItem("token");

    const permissions = JSON.parse(sessionStorage.getItem("permissions"));

    const [permissionModule, setPermissionModule] = useState([]);


    const [admins, setAdmins] = useState([]);

    // pagination and search
    const [filters, setFilters] = useState({
        name: "",
        email: "",
        designation: "",
        status: "",
        page: 1,
        limit: 10,
    });
    const [pagination, setPagination] = useState({
        totalRecords: 0,
        currentPage: 1,
        totalPages: 0,
        limit: 0,
    });

    // Get all admins data
    useEffect(() => {
        const getAdmin = async (e) => {

            try {
                const queryParams = Object.entries(filters)
                    .filter(([_, value]) => value) // Only include parameters with non-empty values
                    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
                    .join("&");
                const res = await axios.get(process.env.REACT_APP_API_URL + `api/admin-users/get-admin-user?${queryParams}`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`,
                        "auth_token": `${auth_token}`,
                    },
                });

                setAdmins(res.data.data.adminUsers);
                setPagination({
                    totalRecords: res.data.data.pagination.totalRecords,
                    currentPage: res.data.data.pagination.currentPage,
                    totalPages: res.data.data.pagination.totalPages,
                    limit: res.data.data.pagination.limit,
                });

            } catch (err) {
                if (err.response?.status === 400) {
                    // Handle invalid token error
                    if (err.response.data === "invalid token") {
                        // navigate("/login"); // Redirect to login page
                    } else {
                        toast.error(err.response.data); // Show other 400 errors
                    }
                } else {
                    // Handle other errors
                    toast.error("No Users found.");
                }
            }
        }
        getAdmin();

        const getRoles = async () => {
            try {
                const res = await axios.get(process.env.REACT_APP_API_URL + "api/role/get-role", {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`,
                        "auth_token": `${auth_token}`,
                    },
                });

                setRoles(res.data.roles);


            } catch (err) {
                if (err.response?.status === 400) {
                    // Handle invalid token error
                    if (err.response.data === "invalid token") {
                        navigate("/login"); // Redirect to login page
                    } else {
                        toast.error(err.response.data); // Show other 400 errors
                    }
                } else {
                    // Handle other errors
                    toast.error("An unexpected error occurred.");
                }
            }
        }
        getRoles();
        if (permissions) {

            setPermissionModule(permissions.find(
                (permission) => permission.Module.moduleName === 'user'
            ));
        }
    }, [refreshKey, filters]);


    // create admin
    const handleCreateAdmin = async (e) => {
        e.preventDefault();

        const postData = {
            "name": fullname,
            "email": email,
            "password": password,
            "designation": designation,
            "phone": "123-456-7890",
            "roleId": permissionRole,
            "reportingTo": reporting,
            "status": true,
        };

        try {
            const res = await axios.post(process.env.REACT_APP_API_URL + "api/admin-users/create-admin-user", postData, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });

            setRefreshKey((prevKey) => prevKey + 1);
            toast.success(res.data.message);

            // Copy both email and password to the clipboard
            const clipboardText = `Email: ${email}\nPassword: ${password}`;
            navigator.clipboard
                .writeText(clipboardText)
                .then(() => {
                    toast.success("Copied to Clipboard.")
                })
                .catch((err) => {
                    console.error('Failed to copy email and password to clipboard:', err);
                });

        } catch (err) {
            if (err.response?.status === 400) {
                // Handle invalid token error
                if (err.response.data === "invalid token") {
                    navigate("/login"); // Redirect to login page
                } else {
                    toast.error(err.response.data); // Show other 400 errors
                }
            } else {
                // Handle other errors
                toast.error("An unexpected error occurred.");
            }
        }

    };


    // format date for table
    const formatDate = (dateString) => {
        const date = new Date(dateString.replace(" ", "T")); // Replace space with T for compatibility
        const options = { month: 'long', day: 'numeric', year: 'numeric' };
        const formattedDate = date.toLocaleDateString('en-US', options);
        const time = date.toLocaleTimeString('en-US', { hour12: false });
        return `${formattedDate} ${time}`;
    };

    useEffect(() => {
        // Initialize the modal on component mount
        const modalElement = document.getElementById("edit-modal");
        if (modalElement) {
            const modal = new Modal(modalElement);
            modal.hide(); // Ensure the modal is initially hidden
        }

        // Initialize the modal on component mount
        const createElement = document.getElementById("popup-modal");
        if (createElement) {
            const create_modal = new Modal(createElement);
            create_modal.hide(); // Ensure the modal is initially hidden
        }
    }, []);

    // get single admin data
    const getAdminData = async (id) => {
        const modalElement = document.getElementById("edit-modal");
        if (modalElement) {
            const modal = new Modal(modalElement);
            modal.show();
        }

        try {
            const res = await axios.get(process.env.REACT_APP_API_URL + `api/admin-users/get-admin-user/${id}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });


            setPermissionRole(res.data.adminUser.roleId);
            setReporting(res.data.adminUser.reportingTo);
            setFullname(res.data.adminUser.name);
            setEmail(res.data.adminUser.email);
            setDesignation(res.data.adminUser.designation);
            setId(res.data.adminUser.id)


        } catch (err) {
            if (err.response?.status === 400) {
                // Handle invalid token error
                if (err.response.data === "invalid token") {
                    navigate("/login"); // Redirect to login page
                } else {
                    toast.error(err.response.data); // Show other 400 errors
                }
            } else {
                // Handle other errors
                toast.error("An unexpected error occurred.");
            }
        }
    }


    // update single admin data
    const handleUpdateAdmin = async (e) => {
        e.preventDefault();
        const update_id = id;

        

        const postData = {
            "name": fullname,
            "email": email,
            "designation": designation,
            "reportingTo": reporting,
            "roleId": permissionRole,
        };

        try {

            const res = await axios.put(process.env.REACT_APP_API_URL + `api/admin-users/update-admin-user/${update_id}`, postData, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });
            toast.success(res.data.message);

        } catch (err) {
            if (err.response?.status === 400) {
                // Handle invalid token error
                if (err.response.data === "invalid token") {
                    navigate("/login"); // Redirect to login page
                } else {
                    toast.error(err.response.data); // Show other 400 errors
                }
            } else {
                // Handle other errors
                toast.error("An unexpected error occurred.");
            }
        }

    };

    const deleteUser = async (id) => {

        try {
            const res = await axios.delete(process.env.REACT_APP_API_URL + `api/admin-users/delete-admin-user/${id}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });
            setRefreshKey((prevKey) => prevKey + 1);

            toast.success(res.data.message);




        } catch (err) {
            console.error("Error in getSales:", err);
        }
    }


    // Function to generate a random password
    const generatePassword = useCallback(() => {
        const characters =
          "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        const passwordLength = 8;
        let newPassword = '';

        for (let i = 0; i < passwordLength; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            newPassword += characters[randomIndex];
        }

        setPassword(newPassword);

    }, []);

    // Generate a password on the first render
    useEffect(() => {
        generatePassword();
    }, [generatePassword]);

    const updatePassword = async (id, email_id) => {

        generatePassword();
        // console.log(password);
        const newPassword = password;
        const postData = {
            "password": newPassword
        };

        try {

            const res = await axios.put(process.env.REACT_APP_API_URL + `api/admin-users/update-admin-user/${id}`, postData, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });
            toast.success(res.data.message);

            const clipboardText = `Email: ${email_id}\nPassword: ${newPassword}`;
            navigator.clipboard
                .writeText(clipboardText)
                .then(() => {
                    toast.success("Copied to Clipboard.")
                })
                .catch((err) => {
                    console.error('Failed to copy email and password to clipboard:', err);
                });


        } catch (err) {
            if (err.response?.status === 400) {
                // Handle invalid token error
                if (err.response.data === "invalid token") {
                    navigate("/login"); // Redirect to login page
                } else {
                    toast.error(err.response.data); // Show other 400 errors
                }
            } else {
                // Handle other errors
                toast.error("An unexpected error occurred.");
            }
        }

    }

    // Handle search functionality
    const handleUserSearch = async (search) => {
        setUserSearchTerm(search);


        try {

            const res = await axios.get(process.env.REACT_APP_API_URL + `api/admin-users/get-admin-user?name=${search}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });
            if (res.data.success) {
                setAdmins(res.data.data.adminUsers);
            }


        } catch (err) {
            if (err.response?.status === 400) {
                // Handle invalid token error
                if (err.response.data === "invalid token") {
                    navigate("/login"); // Redirect to login page
                } else {
                    toast.error(err.response.data); // Show other 400 errors
                }
            } else {
                // Handle other errors
                toast.error("User not found");
            }
        }

    };

    //  update status of user:
    const updateStatus = async (id, status) => {
        const postData = {
            "status": status,
        }
        try {

            const res = await axios.put(process.env.REACT_APP_API_URL + `api/admin-users/update-admin-user/${id}`, postData, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });
            toast.success(res.data.message);
            setRefreshKey((prevKey) => prevKey + 1);

        } catch (err) {
            if (err.response?.status === 400) {
                // Handle invalid token error
                if (err.response.data === "invalid token") {
                    navigate("/login"); // Redirect to login page
                } else {
                    toast.error(err.response.data); // Show other 400 errors
                }
            } else {
                // Handle other errors
                toast.error("An unexpected error occurred.");
            }
        }
    }

    // search and pagination
    const handleFilterChange = (key, value) => {
        setFilters((prev) => ({
            ...prev,
            [key]: value,
            page: 1, // Reset to the first page on filter change
        }));
    };

    const handlePageChange = (newPage) => {
        setFilters((prev) => ({ ...prev, page: newPage }));
    };

    return (
        <div className="bg-gray-100">
            <div className="max-w-7xl mx-auto py-20 p-4 sm:ml-64">
                <ul className="flex md:w-1/2 mt-5 text-md text-black bg-[#F1F5F9]  rounded-lg overflow-hidden shadow-sm">
                    <li className="flex-1">
                        <a
                            href="/admins"
                            className="block m-1 p-2 text-center  bg-white text-black hover:text-black"
                            aria-current="page"
                        >
                            Admins
                        </a>
                    </li>
                    <li className="flex-1">
                        <a
                            href="/roles"
                            className="block m-1 p-2 text-md text-center text-gray-500 rounded-lg shadow-sm"

                        >
                            Manage Roles
                        </a>
                    </li>
                    <li className="flex-1">
                        <a
                            href="/modules"
                            className="block m-1 p-2 text-md text-center text-gray-500 rounded-lg shadow-sm"

                        >
                            Modules
                        </a>
                    </li>
                </ul>



                <div className="md:flex justify-between">
                    {
                        permissionModule.canCreate ?
                            <button type="button"
                                onClick={() => {
                                    const createElement = document.getElementById("popup-modal");
                                    if (createElement) {
                                        const create_modal = new Modal(createElement);
                                        create_modal.show();
                                    }
                                }}
                                className="flex text-white bg-black rounded-full px-5 py-2 my-5">Create new admin</button>
                            : ""
                    }

                    {/* Filters UI */}
                    <div className="items-center md:flex gap-4">
                        <input
                            type="text"
                            placeholder="Search by Name"
                            value={filters.name}
                            onChange={(e) => handleFilterChange("name", e.target.value)}
                            className="my-1 md:my-0 flex border-2 rounded-full px-3 py-2"
                        />
                        <input
                            type="text"
                            placeholder="Search by Email"
                            value={filters.email}
                            onChange={(e) => handleFilterChange("email", e.target.value)}
                            className="my-1 md:my-0 flex border-2 rounded-full px-3 py-2"
                        />
                        <input
                            type="text"
                            placeholder="Search by Desination"
                            value={filters.designation}
                            onChange={(e) => handleFilterChange("designation", e.target.value)}
                            className="my-1 md:my-0 flex border-2 rounded-full px-3 py-2"
                        />
                        <select
                            value={filters.status}
                            onChange={(e) => handleFilterChange("status", e.target.value)}
                            className="my-1 md:my-0 flex border-2 rounded-full px-3 py-2"
                        >
                            <option value="">All Statuses</option>
                            <option value="1">Active</option>
                            <option value="0">Inactive</option>
                        </select>
                    </div>

                </div>

                <div className="w-full  p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">

                    <div className="flex justify-start">
                        <div className="flex flex-col text-left">
                            <h1 className="text-xl text-black font-bold">Admins</h1>

                        </div>

                    </div>

                    <div className="relative overflow-x-auto  sm:rounded-lg mt-5">

                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                    <th scope="col" className="px-6 py-3">
                                        Name
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Email
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Date & Time added
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Status
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Toggle Status
                                    </th>
                                    {
                                        permissionModule.canUpdate ?
                                            <th scope="col" className="px-6 py-3">

                                            </th>
                                            : ""
                                    }
                                    {
                                        permissionModule.canUpdate &&
                                        <th scope="col" className="px-6 py-3">

                                        </th>
                                    }

                                </tr>
                            </thead>
                            <tbody>
                                {
                                    admins.length > 0 ? (
                                        permissionModule.canRead ? admins.map((admin) => (
                                            <tr key={admin.id} className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                                                <th
                                                    scope="row"
                                                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                                >
                                                    {admin.name}
                                                </th>
                                                <td className="px-6 py-4">{admin.email}</td>
                                                <td className="px-6 py-4">{formatDate(admin.createdAt)}</td>


                                                <td className="px-6 py-4">
                                                    {admin.status === true ? <p className="bg-[#DEF7EC] text-sm text-[#03543F] px-3 py-1 rounded-full md:w-full text-center">Active</p> : <p className="bg-[#FBD5D5] text-sm text-red-500 px-3 py-1 rounded-full md:w-full text-center">Inactive</p>}
                                                </td>
                                                <td className="text-center">
                                                    {
                                                        admin.status === true ? <button type="button" onClick={() => updateStatus(admin.id, false)} className="bg-red-600 text-white px-3 py-1 rounded-full">Disable</button>
                                                            : <button type="button" onClick={() => updateStatus(admin.id, true)} className="bg-green-600 text-white px-3 py-1 rounded-full">Enable</button>
                                                    }
                                                </td>
                                                {
                                                    permissionModule.canUpdate ?
                                                        <td className="px-6 py-4">
                                                            <button type="button" onClick={() => getAdminData(admin.id)} className="text-white py-1 px-4 bg-black rounded-full">Edit</button>
                                                        </td> : ""
                                                }
                                                {
                                                    permissionModule.canUpdate ?
                                                        <td className="px-6 py-4">
                                                            <button type="button" onClick={() => updatePassword(admin.id, admin.email)} className="text-white py-2 px-4 bg-blue-800 rounded-full">Generate Password</button>
                                                        </td> : ""
                                                }
                                            </tr>
                                        )) : "Access Denied") :
                                        (
                                            <tr>
                                                <td colSpan="7" className="text-center py-4">
                                                    No users found.
                                                </td>
                                            </tr>
                                        )
                                }


                            </tbody>
                        </table>
                        {/* Pagination */}
                        <div className="flex justify-center mt-4">
                            <button
                                onClick={() => handlePageChange(filters.page - 1)}
                                disabled={filters.page === 1}
                                className="text-white py-1 px-4 bg-black rounded-full mx-1"
                            >
                                Previous
                            </button>
                            <span className="px-4 py-2 mx-1 font-medium">
                                Page {pagination.currentPage} of {pagination.totalPages}
                            </span>
                            <button
                                onClick={() => handlePageChange(filters.page + 1)}
                                disabled={filters.page === pagination.totalPages}
                                className="text-white py-1 px-4 bg-black rounded-full mx-1"
                            >
                                Next
                            </button>
                        </div>
                    </div>

                    <div
                        id="popup-modal"
                        tabIndex={-1}
                        className="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
                    >
                        <div className="relative p-4 w-full max-w-4xl max-h-[60%]">
                            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                <button
                                    type="button"
                                    className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                    onClick={() => {
                                        const createElement = document.getElementById("popup-modal");
                                        if (createElement) {
                                            const create_modal = new Modal(createElement);
                                            create_modal.hide();
                                        }
                                    }}
                                >
                                    <svg
                                        className="w-3 h-3"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 14 14"
                                    >
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                        />
                                    </svg>
                                    <span className="sr-only">Close modal</span>
                                </button>
                                <div className="p-4  text-center">
                                    <div className=" p-6 bg-white shadow rounded-lg">
                                        <h2 className="text-2xl font-bold text-center mb-6">Create new admin</h2>
                                        <form onSubmit={handleCreateAdmin}>
                                            <div className="grid grid-cols-1 md:grid-cols-3 gap-2 text-left">

                                                <div className="md:col-span-1">
                                                    <label className="block mb-1 font-medium">Enter Full Name</label>
                                                </div>
                                                <div className="md:col-span-2">
                                                    <input
                                                        type="text"
                                                        className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                        placeholder="David"
                                                        value={fullname}
                                                        onChange={(e) => setFullname(e.target.value)}
                                                        required
                                                    />
                                                </div>

                                                <div className="md:col-span-1">
                                                    <label className="block mb-1 font-medium">Company Email ID</label>
                                                </div>
                                                <div className="md:col-span-2">
                                                    <input
                                                        type="email"
                                                        className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                        placeholder="UX@crowdafrik.com"
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        required

                                                    />
                                                </div>

                                                <div className="md:col-span-1">
                                                    <label className="block mb-1 font-medium">Designation</label>
                                                </div>
                                                <div className="md:col-span-2">
                                                    <input
                                                        type="text"
                                                        className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                        placeholder="Product Designer"
                                                        value={designation}
                                                        onChange={(e) => setDesignation(e.target.value)}
                                                        required
                                                    />
                                                </div>


                                                <div className="md:col-span-1">
                                                    <label className="block mb-1 font-medium">Reporting to</label>
                                                </div>
                                                <div className="md:col-span-2">
                                                    <input
                                                        type="text"
                                                        placeholder="Search Reporting to..."
                                                        value={userSearchTerm}
                                                        onChange={(e) => handleUserSearch(e.target.value)}
                                                        className="w-full p-2 mb-2 border border-gray-300 rounded"
                                                    />
                                                    <select
                                                        id="user"
                                                        className="w-full p-2 border border-gray-300 rounded"
                                                        value={reporting}
                                                        onChange={(e) => setReporting(e.target.value)}
                                                        required
                                                    >
                                                        <option value="" disabled>-- SELECT MANAGER --</option>
                                                        {
                                                            admins.map((admin) => (
                                                                <option value={admin.id}>{admin.name}</option>
                                                            ))
                                                        }

                                                    </select>
                                                </div>

                                                <div className="md:col-span-1">
                                                    <label className="block mb-1 font-medium">Password</label>
                                                </div>
                                                <div className="md:col-span-2">
                                                    <input
                                                        type="text"
                                                        className="w-3/4 mb-1 p-2 border border-gray-300 rounded"
                                                        placeholder="*********"
                                                        value={password}
                                                        disabled
                                                    />
                                                    <button type="button" onClick={generatePassword} className="text-white bg-blue-700 rounded-lg ml-5 p-2">Generate</button>
                                                </div>

                                                <div className="md:col-span-1">
                                                    <label className="block mb-1 font-medium">Select Role</label>
                                                </div>
                                                <div className="md:col-span-2">
                                                    <select
                                                        id="batchId"
                                                        className="w-3/4 mb-1 p-2 border border-gray-300 rounded"
                                                        value={permissionRole}
                                                        onChange={(e) => setPermissionRole(e.target.value)}
                                                        required
                                                    >
                                                        <option value="" disabled>--Select a Role--</option>
                                                        {
                                                            roles.map((role) => (
                                                                <option key={role.id} value={role.id}>{role.roleName}</option>
                                                            ))
                                                        }

                                                    </select>
                                                </div>

                                            </div>


                                            <div className="mt-5 flex justify-end">



                                                <div>

                                                    <button
                                                        type="submit"
                                                        className="text-white bg-black rounded-full text-sm inline-flex items-center px-5 py-2.5 text-center"
                                                    >
                                                        Create
                                                    </button>
                                                    <button
                                                        onClick={() => {
                                                            const createElement = document.getElementById("popup-modal");
                                                            if (createElement) {
                                                                const create_modal = new Modal(createElement);
                                                                create_modal.hide();
                                                            }
                                                        }}
                                                        type="button"
                                                        className="py-2.5 px-5 ms-3 text-sm font-medium text-black focus:outline-none bg-white rounded-full border border-black hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>


                    {/* edit modal */}
                    <div
                        id="edit-modal"
                        tabIndex={-1}
                        className="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
                    >
                        <div className="relative p-4 w-full max-w-4xl max-h-[60%]">
                            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                <button
                                    type="button"
                                    className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                    onClick={() => {
                                        const modalElement = document.getElementById("edit-modal");
                                        if (modalElement) {
                                            const modal = new Modal(modalElement);
                                            modal.hide();
                                        }
                                    }}
                                >
                                    <svg
                                        className="w-3 h-3"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 14 14"
                                    >
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                        />
                                    </svg>
                                    <span className="sr-only">Close modal</span>
                                </button>
                                <div className="p-4  text-center">
                                    <div className=" p-6 bg-white shadow rounded-lg">
                                        <h2 className="text-2xl font-bold text-center mb-6">Edit admin</h2>
                                        <form onSubmit={handleUpdateAdmin}>
                                            <div className="grid grid-cols-1 md:grid-cols-3 gap-2 text-left">

                                                <div className="md:col-span-1">
                                                    <label className="block mb-1 font-medium">Enter Full Name</label>
                                                </div>
                                                <div className="md:col-span-2">
                                                    <input
                                                        type="text"
                                                        className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                        placeholder="David"
                                                        value={fullname}
                                                        onChange={(e) => setFullname(e.target.value)}
                                                        required
                                                    />
                                                </div>

                                                <div className="md:col-span-1">
                                                    <label className="block mb-1 font-medium">Company Email ID</label>
                                                </div>
                                                <div className="md:col-span-2">
                                                    <input
                                                        type="text"
                                                        className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                        placeholder="UX@crowdafrik.com"
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        required
                                                    />
                                                </div>

                                                <div className="md:col-span-1">
                                                    <label className="block mb-1 font-medium">Designation</label>
                                                </div>
                                                <div className="md:col-span-2">
                                                    <input
                                                        type="text"
                                                        className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                        placeholder="Product Designer"
                                                        value={designation}
                                                        onChange={(e) => setDesignation(e.target.value)}
                                                        required
                                                    />
                                                </div>


                                                <div className="md:col-span-1">
                                                    <label className="block mb-1 font-medium">Reporting to</label>
                                                </div>
                                                <div className="md:col-span-2">
                                                    <input
                                                        type="text"
                                                        placeholder="Search Reporting to..."
                                                        value={userSearchTerm}
                                                        onChange={(e) => handleUserSearch(e.target.value)}
                                                        className="w-full p-2 mb-2 border border-gray-300 rounded"
                                                    />
                                                    <select
                                                        id="user"
                                                        className="w-full p-2 border border-gray-300 rounded"
                                                        value={reporting}
                                                        onChange={(e) => setReporting(e.target.value)}
                                                        required
                                                    >
                                                        <option value="" disabled>-- SELECT MANAGER --</option>
                                                        {
                                                            admins.map((admin) => (
                                                                <option value={admin.id}>{admin.name}</option>
                                                            ))
                                                        }

                                                    </select>
                                                </div>

                                                <div className="md:col-span-1">
                                                    <label className="block mb-1 font-medium">Select Role</label>
                                                </div>
                                                <div className="md:col-span-2">
                                                    <select
                                                        id="batchId"
                                                        className="w-3/4 mb-1 p-2 border border-gray-300 rounded"
                                                        value={permissionRole}
                                                        onChange={(e) => setPermissionRole(e.target.value)}
                                                        required
                                                    >
                                                        <option value="" disabled>--Select a Role--</option>
                                                        {
                                                            roles.map((role) => (
                                                                <option key={role.id} value={role.id}>{role.roleName}</option>
                                                            ))
                                                        }

                                                    </select>
                                                </div>
                                            </div>


                                            <div className="mt-5 flex justify-end">



                                                <div>

                                                    <button
                                                        type="submit"
                                                        className="text-white bg-black rounded-full text-sm inline-flex items-center px-5 py-2.5 text-center"
                                                    >
                                                        Update
                                                    </button>
                                                    <button
                                                        onClick={() => {
                                                            const modalElement = document.getElementById("edit-modal");
                                                            if (modalElement) {
                                                                const modal = new Modal(modalElement);
                                                                modal.hide();
                                                            }
                                                        }}
                                                        type="button"
                                                        className="py-2.5 px-5 ms-3 text-sm font-medium text-black focus:outline-none bg-white rounded-full border border-black hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                        {
                                            permissionModule.canDelete ?
                                                <div className="flex justify-start ">
                                                    <button onClick={() => deleteUser(id)} className="bg-red-600 text-white px-5 py-2 rounded-full">Remove</button>
                                                </div> : ""
                                        }
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    )
}

export default Admin;