import React, { useState, useEffect } from "react";
import ApexCharts from "apexcharts";
import axios from "axios";
import toast from "react-hot-toast";

const Colchart = ({ id, isKioskOwner }) => {
  const token = sessionStorage.getItem("bearer_token");
  const auth_token = sessionStorage.getItem("token");

  const [month, setMonth] = useState([]);
  const [values, setValues] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const chartRef = React.useRef(null); // Reference to the chart instance

  const startYear = new Date().getFullYear();
  const numberOfYears = 15;
  const years = Array.from({ length: numberOfYears }, (_, i) => startYear - i);

  const getSales = async () => {
    try {
      const queryParams = isKioskOwner ? `userId=${id}` : `userId=${id}&agentId=${id}`;
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}api/sales/profile-sale?year=${selectedYear}&${queryParams}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            auth_token: `${auth_token}`,
          },
        }
      );

      const sales = res.data.sales[0];
      const monthArray = Object.keys(sales).filter(
        key => key !== 'totalSalesValue' && key !== 'totalQuantity'
      );

      const valuesArray = monthArray.map((month) => sales[month].totalSalesValue);

      setMonth(monthArray);
      setValues(valuesArray);
    } catch (err) {
      toast.error("No Sales Found");
    }
  };

  const initializeChart = () => {
    const options = {
      series: [{ name: "Sales", data: values }],
      chart: {
        type: "bar",
        height: 400,
        toolbar: { show: false },
        fontFamily: "Inter, sans-serif",
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "50%",
          endingShape: "rounded",
        },
      },
      xaxis: {
        categories: month,
        labels: {
          style: {
            fontFamily: "Inter, sans-serif",
            cssClass: "text-xs font-normal fill-gray-500 dark:fill-gray-400",
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            fontFamily: "Inter, sans-serif",
            cssClass: "text-xs font-normal fill-gray-500 dark:fill-gray-400",
          },
          formatter: (value) => "₦" + value,
        },
      },
      tooltip: {
        enabled: true,
        theme: "light",
        y: {
          formatter: (value) => `₦${value.toLocaleString()}`,
        },
      },
      dataLabels: { enabled: false },
      colors: ["#ADFA1D"],
      grid: {
        borderColor: "#E5E7EB",
        strokeDashArray: 5,
      },
    };

    const chartElement = document.getElementById("column-chart");
    if (chartElement) {
      chartRef.current = new ApexCharts(chartElement, options);
      chartRef.current.render();
    }
  };

  useEffect(() => {
    getSales();
  }, [selectedYear]);

  useEffect(() => {
    if (!chartRef.current) {
      // Initialize chart if it doesn't exist
      initializeChart();
    } else {
      // Update the chart if it exists
      chartRef.current.updateOptions({
        series: [{ name: "Sales", data: values }],
        xaxis: { categories: month },
      });
    }
  }, [month, values]);

  // Cleanup when the component unmounts
  useEffect(() => {
    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
        chartRef.current = null;
      }
    };
  }, []);

  return (
    <div>
      <div className="year-dropdown">
        <select
          id="year-select"
          value={selectedYear}
          onChange={(e) => setSelectedYear(Number(e.target.value))}
          className="w-full p-2 border border-gray-300 rounded"
        >
          <option value="" disabled>-- Select Year --</option>
          {years.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
        {selectedYear && (
          <p className="mt-2 text-sm">
            Selected Year: <strong>{selectedYear}</strong>
          </p>
        )}
      </div>
      <div id="column-chart"></div>
    </div>
  );
};

export default Colchart;
