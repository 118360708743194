import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const KioskData = () => {
    const location = useLocation();
    const isDashboard = location.pathname === '/';

    const [kioskOwners, setKioskowners] = useState([]);


    const token = sessionStorage.getItem("bearer_token");
    const auth_token = sessionStorage.getItem("token");

    const navigate = useNavigate();

    const permissions = JSON.parse(sessionStorage.getItem("permissions"));

    // Get "user" module permissions
    const [permissionModule, setPermissionModule] = useState([]);


    const getSales = async () => {

        try {
            const res = await axios.get(process.env.REACT_APP_API_URL + "api/sales/get-sale", {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });

            const sales = res.data.data.sales;
            

            setKioskowners(sales);
        } catch (err) {
            if (err.response?.status === 400) {
                // Handle invalid token error
                if (err.response.data === "invalid token") {
                    navigate("/login"); // Redirect to login page
                } else {
                    toast.error(err.response.data); // Show other 400 errors
                }
            } else {
                // Handle other errors
                toast.error("An unexpected error occurred.");
            }
        }
    };



    useEffect(() => {
        const fetchSalesData = async () => {
            try {
                await getSales();
            } catch (err) {
                console.error("Error in fetchSalesData:", err);
            }
        };


        fetchSalesData();
        if (permissions) {

            setPermissionModule(permissions.find(
                (permission) => permission.Module.moduleName === 'user'
            ));
        }
    }, []);


    


    return (
        <div className="w-full h-full p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
            <div className="flex items-center justify-start mb-4">
                <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">
                    Recent Kiosk Sales
                </h5>

            </div>
            <div className="flow-root  overflow-y-auto" style={{ maxHeight: 'calc(5 * 4.5rem)' }}>
                <ul role="list" className="divide-y divide-gray-200 dark:divide-gray-700">
                    {
                        permissionModule.canRead ? kioskOwners.map((kiosk) => (
                            <li key={kiosk.id} className="py-3 sm:py-4">
                                <div className="flex items-center">

                                    <div className="flex-1  min-w-0 ms-4 text-left">
                                        <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                                            {kiosk.User.name}
                                        </p>
                                        <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                            {kiosk.User.email}
                                        </p>
                                    </div>
                                    <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                        {kiosk.quantity} Sales


                                    </div>
                                </div>
                            </li>
                        )) : "Access Denied"
                    }

                </ul>
            </div>
            {isDashboard ?
                <div className="flex justify-center mt-5">
                    <a href="/kiosk-sales">
                        <p className="text-md text-blue-400">View All</p>
                    </a>
                </div> : ''
                
            }
        </div>
    );
}

export default KioskData;
