import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal } from "flowbite";
import toast from 'react-hot-toast'
import { useNavigate } from "react-router-dom";

const Batch = () => {
    const [batch, setBatch] = useState('');
    const [superagent, setSuperagent] = useState('');
    const [allocatedby, setAllocatedBy] = useState('');
    const [refreshKey, setRefreshKey] = useState(0);
    const [batches, setBatches] = useState([]);

    const [superagents, setSuperagents] = useState([]);
    const [users, setUsers] = useState([]);

    const [userSearchTerm, setUserSearchTerm] = useState('');
    const [adminSearchTerm, setAdminSearchTerm] = useState('');
    const [id, setId] = useState('');
    const token = sessionStorage.getItem("bearer_token");
    const auth_token = sessionStorage.getItem("token");

    const navigate = useNavigate();

    const permissions = JSON.parse(sessionStorage.getItem("permissions"));

    const [permissionModule, setPermissionModule] = useState([]);

    // pagination and search
    const [filters, setFilters] = useState({
        batch: "",
        status: "",
        startDate: "",
        endDate: "",
        page: 1,
        limit: 10,
    });
    const [pagination, setPagination] = useState({
        totalRecords: 0,
        currentPage: 1,
        totalPages: 0,
        limit: 0,
    });

    useEffect(() => {
        const getBatch = async (e) => {

            try {
                const queryParams = Object.entries(filters)
                    .filter(([_, value]) => value) // Only include parameters with non-empty values
                    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
                    .join("&");
                const res = await axios.get(process.env.REACT_APP_API_URL + `api/batch/get-batches?${queryParams}`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`,
                        "auth_token": `${auth_token}`,
                    },
                });

                setBatches(res.data.data.batches);

                setPagination({
                    totalRecords: res.data.data.pagination.totalRecords,
                    currentPage: res.data.data.pagination.currentPage,
                    totalPages: res.data.data.pagination.totalPages,
                    limit: res.data.data.pagination.limit,
                });

            } catch (err) {
                if (err.response?.status === 400) {
                    // Handle invalid token error
                    if (err.response.data === "invalid token") {
                        navigate("/login"); // Redirect to login page
                    } else {
                        toast.error(err.response.data); // Show other 400 errors
                    }
                } else {
                    // Handle other errors
                    toast.error("No Batches found.");
                }
            }
        }
        getBatch();


        const getUsers = async () => {
            try {
                const res = await axios.get(process.env.REACT_APP_API_URL + "api/admin-users/get-admin-user", {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`,
                        "auth_token": `${auth_token}`,
                    },
                });

                const filteredUsers = res.data.data.adminUsers;

                setUsers(filteredUsers);


            } catch (err) {
                console.error("Error in getUsers:", err);
            }
        };
        getUsers();
        if (permissions) {

            setPermissionModule(permissions.find(
                (permission) => permission.Module.moduleName === 'batch'
            ));
        }
    }, [refreshKey, filters]);

    useEffect(() => {
        const fetchSuperagents = async () => {
            try {
                const res = await axios.get(process.env.REACT_APP_API_URL + "api/user/get-user?isSuperAgent=1", {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`,
                        "auth_token": `${auth_token}`,
                    },
                });

                if (res.data && Array.isArray(res.data.data.users)) {
                    const filteredUsers = res.data.data.users;
                    setSuperagents(filteredUsers);
                } else {
                    console.error("Invalid data format:", res.data);
                }
            } catch (error) {
                console.error("Error fetching superagents:", error);
            }
        };

        fetchSuperagents();
    }, [token, auth_token]);


    const handleCreateBatch = async (e) => {
        e.preventDefault();
        const postData = {
            "batch": batch,
            "date": Date.now(),
            "updatedBy": allocatedby,
            "allocatedTo": superagent,
            "status": true
        };

        try {
            const res = await axios.post(process.env.REACT_APP_API_URL + "api/batch/create-batch", postData, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });

            setRefreshKey((prevKey) => prevKey + 1);
            toast.success(res.data.message);


        } catch (err) {
            if (err.response?.status === 400) {
                // Handle invalid token error
                if (err.response.data === "invalid token") {
                    navigate("/login"); // Redirect to login page
                } else {
                    toast.error(err.response.data); // Show other 400 errors
                }
            } else {
                // Handle other errors
                toast.error("An unexpected error occurred.");
            }
        }
    }
    const formatDate = (dateString) => {
        const date = new Date(dateString.replace(" ", "T")); // Replace space with T for compatibility
        const options = { month: 'long', day: 'numeric', year: 'numeric' };
        const formattedDate = date.toLocaleDateString('en-US', options);
        const time = date.toLocaleTimeString('en-US', { hour12: false });
        return `${formattedDate} ${time}`;
    };


    useEffect(() => {
        // Initialize the modal on component mount
        const modalElement = document.getElementById("edit-modal");
        if (modalElement) {
            const modal = new Modal(modalElement);
            modal.hide(); // Ensure the modal is initially hidden
        }

        // Initialize the modal on component mount
        const createElement = document.getElementById("popup-modal");
        if (createElement) {
            const create_modal = new Modal(createElement);
            create_modal.hide(); // Ensure the modal is initially hidden
        }
    }, []);

    const getBatchData = async (id) => {
        const modalElement = document.getElementById("edit-modal");
        if (modalElement) {
            const modal = new Modal(modalElement);
            modal.show();
        }

        try {
            const res = await axios.get(process.env.REACT_APP_API_URL + `api/batch/get-batch/${id}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });







            setBatch(res.data.batch.batch)
            setId(res.data.batch.id)
            setAllocatedBy(res.data.batch.updatedBy)
            setSuperagent(res.data.batch.allocatedTo)


        } catch (err) {
            console.log(err);

        }
    }


    // update single admin data
    const handleUpdateBatch = async (e) => {
        e.preventDefault();
        const update_id = id;



        const postData = {
            "batch": batch,
            "date": Date.now(),
            "updatedBy": allocatedby,
            "allocatedTo": superagent,
        };

        try {

            const res = await axios.put(process.env.REACT_APP_API_URL + `api/batch/update-batch/${update_id}`, postData, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });
            if (res.data.success) {
                toast.success(res.data.message);
                // setRefreshKey((prevKey) => prevKey + 1);
            }


        } catch (err) {
            if (err.response?.status === 400) {
                // Handle invalid token error
                if (err.response.data === "invalid token") {
                    navigate("/login"); // Redirect to login page
                } else {
                    toast.error(err.response.data); // Show other 400 errors
                }
            } else {
                // Handle other errors
                toast.error("An unexpected error occurred.");
            }
        }

    };

    // Handle search functionality
    const handleUserSearch = async (search) => {
        setUserSearchTerm(search);


        try {

            const res = await axios.get(process.env.REACT_APP_API_URL + `api/user/get-user?name=${search}&isSuperAgent=1`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });
            if (res.data.success) {
                setSuperagents(res.data.data.users);
            }


        } catch (err) {
            if (err.response?.status === 400) {
                // Handle invalid token error
                if (err.response.data === "invalid token") {
                    navigate("/login"); // Redirect to login page
                } else {
                    toast.error(err.response.data); // Show other 400 errors
                }
            } else {
                // Handle other errors
                toast.error("User not found");
            }
        }

    };

    // Handle search functionality
    const handleAdminSearch = async (search) => {
        setAdminSearchTerm(search);


        try {

            const res = await axios.get(process.env.REACT_APP_API_URL + `api/admin-users/get-admin-user?name=${search}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });
            if (res.data.success) {
                setUsers(res.data.data.adminUsers);
            }


        } catch (err) {
            if (err.response?.status === 400) {
                // Handle invalid token error
                if (err.response.data === "invalid token") {
                    navigate("/login"); // Redirect to login page
                } else {
                    toast.error(err.response.data); // Show other 400 errors
                }
            } else {
                // Handle other errors
                toast.error("User not found");
            }
        }

    };

    //  update status of user:
    const updateStatus = async (id, status) => {
        const postData = {
            "status": status,
        }
        try {

            const res = await axios.put(process.env.REACT_APP_API_URL + `api/batch/update-batch/${id}`, postData, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });
            toast.success(res.data.message);
            setRefreshKey((prevKey) => prevKey + 1);

        } catch (err) {
            if (err.response?.status === 400) {
                // Handle invalid token error
                if (err.response.data === "invalid token") {
                    navigate("/login"); // Redirect to login page
                } else {
                    toast.error(err.response.data); // Show other 400 errors
                }
            } else {
                // Handle other errors
                toast.error("An unexpected error occurred.");
            }
        }
    }

    // search and pagination
    const handleFilterChange = (key, value) => {
        setFilters((prev) => ({
            ...prev,
            [key]: value,
            page: 1, // Reset to the first page on filter change
        }));
    };

    const handlePageChange = (newPage) => {
        setFilters((prev) => ({ ...prev, page: newPage }));
    };

    return (
        <div className="max-w-7xl mx-auto py-20 p-4 sm:ml-64">
            <ul className="flex md:w-1/2 mt-5 text-md text-black bg-[#F1F5F9]  rounded-lg overflow-hidden shadow-sm">
                <li className="flex-1">
                    <a
                        href="/add-batch"
                        className="block m-1 p-2 text-md text-center text-gray-500 rounded-lg shadow-sm"

                    >
                        Batches
                    </a>
                </li>
                <li className="flex-1">
                    <a
                        href="/track"
                        className="block m-1 p-2 text-center  bg-white text-black hover:text-black"
                        aria-current="page"
                    >
                        Add Allocation
                    </a>
                </li>
            </ul>

            <div className="md:flex justify-between">

                {
                    permissionModule.canCreate &&
                    <button type="button"
                        onClick={() => {
                            const createElement = document.getElementById("popup-modal");
                            if (createElement) {
                                const create_modal = new Modal(createElement);
                                create_modal.show();
                            }
                        }}
                        className="flex text-white bg-black rounded-full px-5 py-2 my-5">Add allocation</button>
                }

                {/* Filters UI */}
                <div className=" items-center md:flex gap-4">
                    <input
                        type="text"
                        placeholder="Search by Batch Name"
                        value={filters.batch}
                        onChange={(e) => handleFilterChange("batch", e.target.value)}
                        className="my-1 md:my-0 flex border-2 rounded-full px-3 py-2"
                    />
                    <p className="flex">Start Date:</p>
                    <input
                        type="date"
                        placeholder="Search by Start Date"
                        value={filters.startDate}
                        onChange={(e) => handleFilterChange("startDate", e.target.value)}
                        className="my-1 md:my-0 flex border-2 rounded-full px-3 py-2"
                    />
                    <p className="flex">End Date:</p>
                    <input
                        type="date"
                        placeholder="Search by End Date"
                        value={filters.endDate}
                        onChange={(e) => handleFilterChange("endDate", e.target.value)}
                        className="my-1 md:my-0 flex border-2 rounded-full px-3 py-2"
                    />


                    <select
                        value={filters.status}
                        onChange={(e) => handleFilterChange("status", e.target.value)}
                        className="my-1 md:my-0 flex border-2 rounded-full px-3 py-2"
                    >
                        <option value="">All Statuses</option>
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                    </select>
                </div>

            </div>

            <div className="w-full  p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">

                <div className="flex justify-start">
                    <div className="flex flex-col text-left">
                        <h1 className="text-xl text-black font-bold">Batches</h1>
                        <h1 className="text-md text-gray-400">Add new lottery batches</h1>
                    </div>

                </div>

                <div className="relative overflow-x-auto  sm:rounded-lg mt-5">

                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="px-6 py-3">
                                    Batch
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Date & Time
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Updated By
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Allocated to
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Status
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Toggle Status
                                </th>
                                {
                                    permissionModule.canUpdate &&
                                    <th scope="col" className="px-6 py-3">

                                    </th>
                                }

                            </tr>
                        </thead>
                        <tbody>
                            {
                                batches.length > 0 ? (
                                    permissionModule.canRead ? batches.map((batch) => (
                                        <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                                            <th
                                                scope="row"
                                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                            >
                                                {batch.batch}
                                            </th>
                                            <td className="px-6 py-4">{formatDate(batch.date)}</td>
                                            <td className="px-6 py-4">{batch.AdminUser.name}</td>
                                            <td className="px-6 py-4">{batch.User.name}</td>
                                            <td className="px-6 py-4">
                                                {batch.status === true ? <p className="bg-[#DEF7EC] text-sm text-[#03543F] px-3 py-1 rounded-full md:w-3/4 text-center">Active</p> : <p className="bg-[#FBD5D5] text-sm text-red-500 px-3 py-1 rounded-full md:w-3/4 text-center">Inactive</p>}

                                            </td>
                                            <td className="text-center">
                                                {
                                                    batch.status === true ? <button type="button" onClick={() => updateStatus(batch.id, "false")} className="bg-red-600 text-white px-3 py-1 rounded-full">Disable</button>
                                                        : <button type="button" onClick={() => updateStatus(batch.id, true)} className="bg-green-600 text-white px-3 py-1 rounded-full">Enable</button>
                                                }
                                            </td>
                                            {
                                                permissionModule.canUpdate &&
                                                <td className="px-6 py-4">
                                                    <button type="button" onClick={() => getBatchData(batch.id)} className="text-white py-1 px-4 bg-black rounded-full">Edit</button>
                                                </td>
                                            }

                                        </tr>
                                    )) : "Access Denied") :
                                    (
                                        <tr>
                                            <td colSpan="7" className="text-center py-4">
                                                No batches found.
                                            </td>
                                        </tr>
                                    )
                            }

                        </tbody>
                    </table>
                    {/* Pagination */}
                    <div className="flex justify-center mt-4">
                        <button
                            onClick={() => handlePageChange(filters.page - 1)}
                            disabled={filters.page === 1}
                            className="text-white py-1 px-4 bg-black rounded-full mx-1"
                        >
                            Previous
                        </button>
                        <span className="px-4 py-2 mx-1 font-medium">
                            Page {pagination.currentPage} of {pagination.totalPages}
                        </span>
                        <button
                            onClick={() => handlePageChange(filters.page + 1)}
                            disabled={filters.page === pagination.totalPages}
                            className="text-white py-1 px-4 bg-black rounded-full mx-1"
                        >
                            Next
                        </button>
                    </div>
                </div>

                <div
                    id="popup-modal"
                    tabIndex={-1}
                    className="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
                >
                    <div className="relative p-4 w-full max-w-4xl max-h-[60%]">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                            <button
                                type="button"
                                className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                onClick={() => {
                                    const createElement = document.getElementById("popup-modal");
                                    if (createElement) {
                                        const create_modal = new Modal(createElement);
                                        create_modal.hide();
                                    }
                                }}
                            >
                                <svg
                                    className="w-3 h-3"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 14 14"
                                >
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                    />
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                            <div className="p-4  text-center">
                                <div className=" p-6 bg-white shadow rounded-lg">
                                    <h2 className="text-2xl font-bold text-center mb-6">Add Allocation</h2>
                                    <form onSubmit={handleCreateBatch}>
                                        <div className="grid grid-cols-1 md:grid-cols-3 gap-2 text-left">

                                            <div className="md:col-span-1 flex items-center ">
                                                <label className="block mb-1 font-medium">Batch Number</label>
                                            </div>
                                            <div className="md:col-span-2">
                                                <input
                                                    type="text"
                                                    className="w-full mb-5 p-2 border border-gray-300 rounded"
                                                    placeholder="001"
                                                    value={batch}
                                                    onChange={(e) => setBatch(e.target.value)}
                                                    required
                                                />
                                            </div>

                                            <div className="md:col-span-1 flex items-center ">
                                                <label className="block mb-1 font-medium">Super Agent</label>
                                            </div>
                                            <div className="md:col-span-2">
                                                <input
                                                    type="text"
                                                    placeholder="Search Superagent..."
                                                    value={userSearchTerm}
                                                    onChange={(e) => handleUserSearch(e.target.value)}
                                                    className="w-full p-2 mb-2 border border-gray-300 rounded"
                                                />
                                                <select
                                                    id="superagent"
                                                    className="w-full p-2 mb-5 border border-gray-300 rounded"
                                                    value={superagent}
                                                    onChange={(e) => setSuperagent(e.target.value)}
                                                    required
                                                >
                                                    <option value="" disabled>-- SELECT SUPERAGENT --</option>
                                                    {
                                                        superagents.map((superagent) => (
                                                            <option value={superagent.id}>{superagent.name}</option>
                                                        ))
                                                    }

                                                </select>
                                            </div>

                                            <div className="md:col-span-1 flex items-center mt-5">
                                                <label className="block mb-1 font-medium">Allocated By</label>
                                            </div>
                                            <div className="md:col-span-2">
                                                <input
                                                    type="text"
                                                    placeholder="Search SuperAdmin..."
                                                    value={adminSearchTerm}
                                                    onChange={(e) => handleAdminSearch(e.target.value)}
                                                    className="w-full p-2 mb-2 border border-gray-300 rounded"
                                                />
                                                <select
                                                    id="user"
                                                    className="w-full p-2 border border-gray-300 rounded"
                                                    value={allocatedby}
                                                    onChange={(e) => setAllocatedBy(e.target.value)}
                                                    required
                                                >
                                                    <option value="" disabled>-- SELECT SUPERADMIN --</option>
                                                    {
                                                        users.map((user) => (
                                                            <option value={user.id}>{user.name}</option>
                                                        ))
                                                    }

                                                </select>
                                            </div>

                                        </div>


                                        <div className="mt-5 flex justify-end">



                                            <div>

                                                <button
                                                    type="submit"
                                                    className="text-white bg-black rounded-full text-sm inline-flex items-center px-5 py-2.5 text-center"
                                                >
                                                    Add
                                                </button>
                                                <button
                                                    onClick={() => {
                                                        const createElement = document.getElementById("popup-modal");
                                                        if (createElement) {
                                                            const create_modal = new Modal(createElement);
                                                            create_modal.hide();
                                                        }
                                                    }}
                                                    type="button"
                                                    className="py-2.5 px-5 ms-3 text-sm font-medium text-black focus:outline-none bg-white rounded-full border border-black hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>


                {/* edit modal */}
                <div
                    id="edit-modal"
                    tabIndex={-1}
                    className="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
                >
                    <div className="relative p-4 w-full max-w-4xl max-h-[60%]">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                            <button
                                type="button"
                                className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                onClick={() => {
                                    const modalElement = document.getElementById("edit-modal");
                                    if (modalElement) {
                                        const modal = new Modal(modalElement);
                                        modal.hide();
                                    }
                                }}
                            >
                                <svg
                                    className="w-3 h-3"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 14 14"
                                >
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                    />
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                            <div className="p-4  text-center">
                                <div className=" p-6 bg-white shadow rounded-lg">
                                    <h2 className="text-2xl font-bold text-center mb-6">Edit allocation</h2>
                                    <form onSubmit={handleUpdateBatch}>
                                        <div className="grid grid-cols-1 md:grid-cols-3 gap-2 text-left">

                                            <div className="md:col-span-1 flex items-center ">
                                                <label className="block mb-1 font-medium">Batch Number</label>
                                            </div>
                                            <div className="md:col-span-2">
                                                <input
                                                    type="text"
                                                    className="w-full mb-5 p-2 border border-gray-300 rounded"
                                                    placeholder="001"
                                                    value={batch}
                                                    onChange={(e) => setBatch(e.target.value)}
                                                    required
                                                />
                                            </div>

                                            <div className="md:col-span-1 flex items-center ">
                                                <label className="block mb-1 font-medium">Super Agent</label>
                                            </div>
                                            <div className="md:col-span-2">
                                                <input
                                                    type="text"
                                                    placeholder="Search Superagent..."
                                                    value={userSearchTerm}
                                                    onChange={(e) => handleUserSearch(e.target.value)}
                                                    className="w-full p-2 mb-2 border border-gray-300 rounded"
                                                />
                                                <select
                                                    id="superagent"
                                                    className="w-full mb-5 p-2 border border-gray-300 rounded"
                                                    value={superagent}
                                                    onChange={(e) => setSuperagent(e.target.value)}
                                                    required
                                                >
                                                    <option value="" disabled>-- SELECT SUPERAGENT --</option>
                                                    {
                                                        superagents.map((superagent) => (
                                                            <option value={superagent.id}>{superagent.name}</option>
                                                        ))
                                                    }

                                                </select>
                                            </div>

                                            <div className="md:col-span-1 flex items-center ">
                                                <label className="block mb-1 font-medium">Allocated By</label>
                                            </div>
                                            <div className="md:col-span-2">
                                                <input
                                                    type="text"
                                                    placeholder="Search SuperAdmin..."
                                                    value={adminSearchTerm}
                                                    onChange={(e) => handleAdminSearch(e.target.value)}
                                                    className="w-full p-2 mb-2 border border-gray-300 rounded"
                                                />
                                                <select
                                                    id="user"
                                                    className="w-full p-2 border border-gray-300 rounded"
                                                    value={allocatedby}
                                                    onChange={(e) => setAllocatedBy(e.target.value)}
                                                    required
                                                >
                                                    <option value="" disabled>-- SELECT SUPERADMIN --</option>
                                                    {
                                                        users.map((user) => (
                                                            <option value={user.id}>{user.name}</option>
                                                        ))
                                                    }

                                                </select>
                                            </div>

                                        </div>


                                        <div className="mt-5 flex justify-end">
                                            <div>

                                                <button
                                                    data-modal-hide="edit-modal"
                                                    type="submit"
                                                    className="text-white bg-black rounded-full text-sm inline-flex items-center px-5 py-2.5 text-center"
                                                >
                                                    Edit
                                                </button>
                                                <button
                                                    onClick={() => {
                                                        const modalElement = document.getElementById("edit-modal");
                                                        if (modalElement) {
                                                            const modal = new Modal(modalElement);
                                                            modal.hide();
                                                        }
                                                    }}
                                                    type="button"
                                                    className="py-2.5 px-5 ms-3 text-sm font-medium text-black focus:outline-none bg-white rounded-full border border-black hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default Batch;